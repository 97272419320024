import classNames from "classnames";

function InfoCard({ heading, subHeading, centered = false }) {
  const contentClass = classNames("content", {
    "text-center": centered,
  });

  return (
    <div className="card mx-0 mb-2 card-style">
      <div className={contentClass}>
        <h4>{heading}</h4>
        <p>{subHeading}</p>
      </div>
    </div>
  );
}

export default InfoCard;
