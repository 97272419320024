import { useParams } from "react-router-dom";
import Home from "./Home";
import { Layout } from "../App";

function Feed({ frameRef }) {
  const { sort } = useParams();
  return (
    <Layout frameRef={frameRef} sort={sort}>
      <Home frameRef={frameRef} sort={sort} key={sort} />
    </Layout>
  );
}

export default Feed;
