import "./styles/theme/css/fonts/css/all.css";
import "./styles/theme/scss/bootstrap.scss";
import "./styles/theme/scss/style.scss";
import "swiper/scss";
import "swiper/scss/effect-cube";
import "swiper/scss/free-mode";
import "swiper/scss/navigation";
import "swiper/scss/thumbs";
import "swiper/scss/free-mode";
import "swiper/scss/pagination";

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";
import { setVisitorHeader } from "./utils/axiosConfig";

import ReactGA from "react-ga4";
import { clarity } from "react-microsoft-clarity";

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize("G-TWK1FGLKSK");
  clarity.init("k9e2jlh1ah");
}

async function setFingerprint() {
  try {
    let visitorId = localStorage.getItem("visitorId");
    if (!visitorId) {
      const fingerprint = await getCurrentBrowserFingerPrint();
      if (fingerprint) {
        visitorId = fingerprint.toString();
        localStorage.setItem("visitorId", visitorId);
      } else {
        console.error("Failed to generate a valid fingerprint");
      }
    }
    setVisitorHeader(visitorId);
  } catch (error) {
    console.error("Error setting fingerprint:", error);
  }
}

async function init() {
  if ("scrollRestoration" in window.history) {
    window.history.scrollRestoration = "manual";
  }
  await setFingerprint();
  const el = document.getElementById("root");
  const root = ReactDOM.createRoot(el);
  root.render(<App />);
}

init();
