import { useEffect } from "react";

function useScrollEffect(headerRef, titleRef, frameRef = null) {
  useEffect(() => {
    const targetElement =
      frameRef && frameRef.current ? frameRef.current : window;
    // const originalOverflow = document.body.style.overflow;

    const handleScroll = () => {
      const scrollY =
        targetElement === window
          ? document.documentElement.scrollTop
          : targetElement.scrollTop;

      const inside_header = scrollY <= 80;
      const passed_header = scrollY >= 80;

      const inside_title = scrollY <= 40;
      const passed_title = scrollY >= 40;

      if (headerRef.current) {
        if (inside_header) {
          headerRef.current.classList.remove("header-active");
        }
        if (passed_header) {
          headerRef.current.classList.add("header-active");
        }
      }

      if (titleRef.current) {
        if (inside_title) {
          titleRef.current.style.opacity = "1";
        }
        if (passed_title) {
          titleRef.current.style.opacity = "0";
        }
      }
    };

    // if (frameRef && frameRef.current) {
    //   document.body.style.overflow = "hidden";
    // }
    targetElement.addEventListener("scroll", handleScroll);

    return () => {
      // document.body.style.overflow = originalOverflow;
      targetElement.removeEventListener("scroll", handleScroll);
    };
  }, [headerRef, titleRef, frameRef]);
}

export default useScrollEffect;
