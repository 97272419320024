import { useEffect } from "react";

function useScrollToReel(frameRef, scrollId) {
  useEffect(() => {
    if (scrollId) {
      const element = document.getElementById(scrollId);
      if (element) {
        setTimeout(() => {
          if (frameRef && frameRef.current) {
            const containerTop =
              frameRef.current.getBoundingClientRect().top + window.scrollY;
            const elementTop =
              element.getBoundingClientRect().top + window.scrollY;
            const relativeTop = elementTop - containerTop;

            frameRef.current.scrollTo({
              top: relativeTop,
              behavior: "smooth",
            });
          } else {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 100);
      }
    }
  }, [scrollId, frameRef]);
}

export default useScrollToReel;
