import React, { useState, useEffect } from "react";

function CustomSeeker({ playerRef }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const updateProgress = () => {
      if (playerRef.current) {
        const currentProgress =
          (playerRef.current.currentTime / playerRef.current.duration) * 100;
        setProgress(currentProgress);
        requestAnimationFrame(updateProgress);
      }
    };
    requestAnimationFrame(updateProgress);
  }, [playerRef]);

  const handleSeek = (event) => {
    const seekerWidth = event.target.clientWidth;
    const clickPosition = event.nativeEvent.offsetX;
    const seekTime = (clickPosition / seekerWidth) * playerRef.current.duration;
    playerRef.current.currentTime = seekTime;
  };

  return (
    <div className="seeker-container" onClick={handleSeek}>
      <div className="seeker-progress" style={{ width: `${progress}%` }}></div>
    </div>
  );
}

export default CustomSeeker;
