import classNames from "classnames";
import { useNavigate, useLocation } from "react-router-dom";

const siteLinks = [
  {
    path: "/privacy-policy",
    id: "nav-privacy-policy",
    icon: "fa-shield-alt",
    gradient: "gradient-blue",
    text: "Privacy Policy",
  },
  {
    path: "/terms-of-service",
    id: "nav-terms-of-service",
    icon: "fa-gavel",
    gradient: "gradient-orange",
    text: "Terms of Service",
  },
  {
    path: "/contact-us",
    id: "nav-contact-us",
    icon: "fa-envelope",
    gradient: "gradient-green",
    text: "Contact Us",
  },
  {
    path: "/about-us",
    id: "nav-about-us",
    icon: "fa-info-circle",
    gradient: "gradient-teal",
    text: "About Us",
  },
];

const SiteLinks = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="list-group list-custom-small list-menu">
      {siteLinks.map((link) => (
        <a
          key={link.id}
          tabIndex="0"
          role="button"
          href={link.path}
          id={link.id}
          onClick={(e) => {
            e.preventDefault();
            navigate(link.path);
          }}
          className={classNames({
            "active-nav": location.pathname === link.path,
          })}
        >
          <i className={`fa ${link.icon} ${link.gradient} color-white`} />
          <span>{link.text}</span>
          <i className="fa fa-angle-right" />
        </a>
      ))}
    </div>
  );
};

export default SiteLinks;
