import { useEffect, useRef } from "react";

function useClickOutside(refsArray, callback) {
  const refs = useRef(refsArray);

  useEffect(() => {
    refs.current = refsArray;
  }, [refsArray]);

  useEffect(() => {
    const handler = (event) => {
      if (!refs.current.some((ref) => ref.current.contains(event.target))) {
        callback();
      }
    };

    document.addEventListener("click", handler, true);

    return () => {
      document.removeEventListener("click", handler, true);
    };
  }, [callback]);
}

export default useClickOutside;
