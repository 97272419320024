import { useState, useEffect } from "react";

function SimpleReelCard({
  reel,
  onClick,
  height,
  width,
  style,
  isTouchMoved,
  touchedCardId,
  setTouchedCardId,
  ...rest
}) {
  const [isHovered, setIsHovered] = useState(false);

  const { id, title, mux_playback_id, short_id } = reel;

  const preview = `//image.mux.com/${mux_playback_id}/animated.gif`;
  const thumbnail = `//image.mux.com/${mux_playback_id}/thumbnail.png?time=2`;

  useEffect(() => {
    if (isTouchMoved || id !== touchedCardId) {
      setIsHovered(false);
    }
  }, [id, touchedCardId, isTouchMoved]);

  const handleTouchStart = () => {
    setIsHovered(true);
    setTouchedCardId(id);
  };

  const bgImage = isHovered ? preview : thumbnail;

  return (
    <a
      href={`/peeks/${short_id}`}
      style={{ textDecoration: "none", display: "block" }}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onTouchStart={handleTouchStart}
      id={reel.short_id}
    >
      <div
        className="card mx-0 mb-2 card-style"
        style={{
          backgroundImage: `url(${bgImage})`,
          height: height,
          width: width,
          cursor: "pointer",
          ...style,
        }}
        {...rest}
      >
        {!isHovered && (
          <>
            <div className="card-top">
              <div
                tabIndex="0"
                role="button"
                className="play-reel icon icon-xxs bg-white color-black rounded-xl mt-3 me-2 float-end"
              >
                <i className="fa fa-video"></i>
              </div>
            </div>
            <div className="card-bottom">
              <h6 className="color-white font-800 mb-3 pb-1 ps-3 reel-text">
                {title}
              </h6>
            </div>
            <div className="card-overlay bg-gradient"></div>
          </>
        )}
      </div>
    </a>
  );
}

export default SimpleReelCard;
