import { create } from "zustand";
import axios from "../utils/axiosConfig";

const useCategoriesStore = create((set, get) => ({
  categories: [{ label: "All" }],
  loading: false,
  error: null,
  addCategory: (category) =>
    set((state) => ({ categories: [...state.categories, category] })),
  fetchCategories: async () => {
    set({ loading: true });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/categories`
      );
      const newCategories = response.data.data;

      set((state) => ({
        categories: state.categories.concat(
          newCategories.filter((newCat) =>
            state.categories.every(
              (existCat) => existCat.label !== newCat.label
            )
          )
        ),
      }));
    } catch (error) {
      console.error("Failed to fetch categories:", error);
      set({ error: "Failed to fetch categories." });
    } finally {
      set({ loading: false });
    }
  },
}));

export default useCategoriesStore;
