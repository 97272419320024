import { create } from "zustand";

const useReelsStore = create((set, get) => ({
  reelsByPath: {},

  setReelsForPath: (path, newReels, nextPageToken) => {
    set((state) => {
      const existingReels = state.reelsByPath[path]?.reels || [];
      const mergedReels = [...existingReels, ...newReels].reduce(
        (acc, reel) => {
          if (!acc.some((r) => r.id === reel.id)) {
            acc.push(reel);
          }
          return acc;
        },
        []
      );

      return {
        reelsByPath: {
          ...state.reelsByPath,
          [path]: { reels: mergedReels, nextPageToken },
        },
      };
    });
  },

  getReelsForPath: (path) => {
    return get().reelsByPath[path] || { reels: [], nextPageToken: null };
  },

  resetReelsData: () => {
    set({ reelsByPath: {} });
  },
}));

export default useReelsStore;
