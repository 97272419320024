function FormattedContent({ content }) {
  const paragraphs = content.split(/\r\n|\r|\n/);

  return (
    <>
      {paragraphs.map((paragraph, index) => (
        <p className="mb-2" key={index}>
          {paragraph}
        </p>
      ))}
    </>
  );
}

export default FormattedContent;
