import { createContext, useState, useContext } from "react";

const HeaderContext = createContext();

export const useHeaderContext = () => useContext(HeaderContext);

export const HeaderProvider = ({ children }) => {
  const [mainMenuActive, setMainMenuActive] = useState(false);
  const [pageTitle, setPageTitle] = useState("Home");

  return (
    <HeaderContext.Provider
      value={{ mainMenuActive, setMainMenuActive, pageTitle, setPageTitle }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
