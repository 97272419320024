import { useMute } from "../../contexts/MuteContext";
import { useEffect } from "react";

function MuteButton({ playerRef, style }) {
  const { isMuted, toggleMute } = useMute();

  useEffect(() => {
    if (playerRef && playerRef.current) {
      playerRef.current.muted = isMuted;
    }
  }, [isMuted, playerRef]);

  return (
    <button onClick={toggleMute} className="mute-unmute-button" style={style}>
      {isMuted ? (
        <i className="fa-solid fa-volume-xmark fa-shake"></i>
      ) : (
        <i className="fa-solid fa-volume-low"></i>
      )}
    </button>
  );
}

export default MuteButton;
