import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../utils/axiosConfig";
import PreLoader from "../components/utils/PreLoader";
import useReelsStore from "../stores/reelsStore";

function Reels() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { reels, setReelsForPath } = useReelsStore((state) => ({
    reels: state.getReelsForPath("/").reels,
    setReelsForPath: state.setReelsForPath,
  }));

  useEffect(() => {
    const fetchReels = async () => {
      if (reels.length === 0) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/feed`
          );
          const {
            data: fetchedReels,
            next_page_token: nextPageToken,
          } = response.data;

          setReelsForPath("/", fetchedReels, nextPageToken);
        } catch (error) {
          console.error("Failed to fetch reels:", error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };
    fetchReels();
  }, [reels, setReelsForPath]);

  useEffect(() => {
    if (!isLoading && reels.length) {
      const reel = reels[Math.floor(Math.random() * reels.length)];
      if (reel && reel.short_id) {
        navigate(`/peeks/${reel.short_id}`, {
          state: { referrer: "/", reel },
        });
      }
    }
  }, [reels, navigate, isLoading]);

  return <PreLoader />;
}

export default Reels;
