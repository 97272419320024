import { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Mousewheel,
  Virtual,
  FreeMode,
  Navigation,
  Thumbs,
} from "swiper/modules";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { DiscussionEmbed } from "disqus-react";
import { Helmet } from "react-helmet-async";
import truncate from "lodash/truncate";
import isbot from "isbot";
import axios from "../utils/axiosConfig";

import useReelsStore from "../stores/reelsStore";
import Reel from "../components/reels/Reel";
import useDarkMode from "../hooks/useDarkMode";
import PreLoader from "../components/utils/PreLoader";
import { setHistory } from "../utils/util";
import Menu from "../components/layout/Menu";
import useClickOutside from "../hooks/useClickOutside";
import ShareMenu from "../components/layout/ShareMenu";
import FormattedContent from "../components/utils/FormattedContent";

function ReelList({ frameRef }) {
  useDarkMode();
  const { shortId } = useParams();
  const location = useLocation();

  const reelState = location.state?.reel;
  const [currentReel, setCurrentReel] = useState(reelState);

  const [isPlaying, setIsPlaying] = useState(false);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  const [currentReels, setCurrentReels] = useState([]);
  const [nextPageToken, setNextPageToken] = useState(null);

  const divEl = useRef();
  const [commentMenuActive, setCommentMenuActive] = useState(false);
  const [shopMenuActive, setShopMenuActive] = useState(false);
  const [shareMenuActive, setShareMenuActive] = useState(false);
  const [isNativeShareAvailable, setIsNativeShareAvailable] = useState(false);

  const [currentProduct, setCurrentProduct] = useState(null);
  const [showBuyBtn, setShowBuyBtn] = useState(false);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const fetchReel = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/videos/${id}`
      );
      const data = response.data.data;
      if (data) {
        return data;
      }
    } catch (error) {
      console.error("Failed to fetch reel data:", error);
    }
  };

  const fetchMoreReels = useCallback(
    async (videoId) => {
      try {
        const params = {};
        if (nextPageToken) {
          params.next_page_token = nextPageToken;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/videos/similar/${videoId}`,
          {
            params,
          }
        );

        const {
          data: fetchedReels,
          next_page_token: newNextPageToken,
        } = response.data;

        setNextPageToken(newNextPageToken);
        if (fetchedReels && fetchedReels.length) {
          setCurrentReels((prevReels) => {
            const uniqueReels = fetchedReels.filter(
              (fetchedReel) =>
                !prevReels.some(
                  (cReel) => cReel.short_id === fetchedReel.short_id
                )
            );
            return [...prevReels, ...uniqueReels];
          });
        }
      } catch (error) {
        console.error("Failed to fetch more peeks:", error);
      }
    },
    [nextPageToken]
  );

  useEffect(() => {
    const resetScrollPosition = () => {
      if (frameRef && frameRef.current) {
        frameRef.current.scrollTop = 0;
      } else {
        window.scrollTo(0, 0);
      }
    };
    resetScrollPosition();
  }, [frameRef]);

  useEffect(() => {
    const updateHeight = () => {
      if (frameRef && frameRef.current) {
        setViewportHeight(frameRef.current.clientHeight);
      } else {
        setViewportHeight(window.innerHeight);
      }
    };
    updateHeight();
  }, [frameRef]);

  useEffect(() => {
    const newShortId = window.location.pathname.split("/").pop();
    const currentShortId = shortId !== newShortId ? newShortId : shortId;
    if (currentReel) {
      const relatedReels = getReelsBySubCategoryOrCategory(currentReel);
      setCurrentReels([currentReel, ...relatedReels]);
      fetchMoreReels(currentReel.id);
    } else {
      fetchReel(currentShortId).then((reel) => {
        setCurrentReel(reel);
        const relatedReels = getReelsBySubCategoryOrCategory(reel);
        setCurrentReels([reel, ...relatedReels]);
        fetchMoreReels(reel.id);
      });
    }
  }, [shortId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleResize = () => {
      const newHeight =
        frameRef && frameRef.current
          ? frameRef.current.clientHeight
          : window.innerHeight;
      setViewportHeight(newHeight);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [frameRef]);

  useEffect(() => {
    const originalOverflow = document.body.style.overflow;
    const originalPosition = document.body.style.position;
    const originalHeight = document.body.style.height;

    document.body.style.overflow = "hidden";
    document.body.style.position = "relative";
    document.body.style.height = "100%";

    return () => {
      document.body.style.overflow = originalOverflow;
      document.body.style.position = originalPosition;
      document.body.style.height = originalHeight;
    };
  }, []);

  const getReelsBySubCategoryOrCategory = (targetReel) => {
    const allReels = Object.values(useReelsStore.getState().reelsByPath)
      .flatMap((pathReels) => pathReels.reels)
      .filter((reel) => reel.id !== targetReel.id);

    const filterReelsByCriteria = (criteriaFn) =>
      allReels.filter((reel) => criteriaFn(reel));

    const sameSubCategories = filterReelsByCriteria((reel) =>
      reel.sub_categories.some((sub) =>
        targetReel.sub_categories.map((tsub) => tsub.id).includes(sub.id)
      )
    );

    const sameCategories = filterReelsByCriteria((reel) =>
      reel.categories.some((cat) =>
        targetReel.categories.map((tcat) => tcat.id).includes(cat.id)
      )
    );

    const combined = [...sameSubCategories, ...sameCategories];
    return Array.from(new Set(combined.map((reel) => reel.id))).map((id) =>
      combined.find((reel) => reel.id === id)
    );
  };

  const handleIsPlaying = () => {
    setIsPlaying(true);
  };

  const handleSlideChange = (e) => {
    const cReel = currentReels[e.activeIndex];
    setCurrentReel(cReel);
    setHistory("peeks", cReel.short_id);

    if (
      e.activeIndex === currentReels.length - 2 &&
      e.swipeDirection === "next" &&
      nextPageToken
    ) {
      fetchMoreReels(cReel.id);
    }
  };

  const handleCommentMenuClick = useCallback(() => {
    setShopMenuActive(false);
    setShareMenuActive(false);
    setCommentMenuActive((prev) => !prev);
  }, []);

  const handleShopMenuClick = useCallback((product) => {
    setCurrentProduct(product);
    setShowBuyBtn(true);
    setCommentMenuActive(false);
    setShareMenuActive(false);
    setShopMenuActive((prev) => !prev);
  }, []);

  const handleShareMenuClick = useCallback(() => {
    if (navigator.share) {
      const shareData = {
        title: document.title,
        url: window.location.href,
      };
      navigator.share(shareData).catch(console.error);
      setIsNativeShareAvailable(true);
    } else {
      setShopMenuActive(false);
      setCommentMenuActive(false);
      setShareMenuActive((prev) => !prev);
    }
  }, []);

  const closeReelMenus = useCallback(() => {
    setCurrentProduct(null);
    setShowBuyBtn(false);
    setThumbsSwiper(null);
    setCommentMenuActive(false);
    setShopMenuActive(false);
    setShareMenuActive(false);
  }, []);

  useClickOutside([divEl], closeReelMenus);

  const getPageTitle = () => {
    return currentReel
      ? `${currentReel.title} | Quik Peeks`
      : "Reel Details | Quik Peeks";
  };

  const getPageDescription = () => {
    if (currentReel && currentReel.desc) {
      const cleanDescription = currentReel.desc.replace(/\s+/g, " ").trim();
      return truncate(cleanDescription, {
        length: 160,
        separator: /,?\.* +/,
      });
    } else {
      return "Explore unique and interesting products in our peeks. Discover the latest user-generated content at Quik Peeks.";
    }
  };

  const getStructuredData = (reel) => {
    if (!reel) return null;

    // Basic video information
    const durationInSeconds = parseInt(reel.duration, 10);
    const basicInfo = {
      "@context": "http://schema.org",
      "@type": "VideoObject",
      name: reel.title,
      description: reel.desc,
      thumbnailUrl: `https://quikpeeks.com/thumbnails/${reel.short_id}/${reel.id}.png`,
      uploadDate: new Date(reel.created_at * 1000).toISOString(),
      duration: `PT${Math.floor(durationInSeconds / 60)}M${
        durationInSeconds % 60
      }S`,
      contentUrl: `https://quikpeeks.com/peeks/${reel.short_id}`,
      interactionStatistic: [
        {
          "@type": "InteractionCounter",
          interactionType: "http://schema.org/LikeAction",
          userInteractionCount: reel.likes_count,
        },
        {
          "@type": "InteractionCounter",
          interactionType: "http://schema.org/DislikeAction",
          userInteractionCount: reel.dislikes_count,
        },
      ],
      genre: reel.categories.map((category) => category.name),
      keywords: reel.tags.join(", "),
    };

    // Check if timeline is empty and add 'hasPart' only if it's not
    const isTimelineEmpty =
      !reel.timeline || Object.keys(reel.timeline).length === 0;
    if (!isTimelineEmpty) {
      const sortedProducts = reel.products
        .map((product) => ({
          ...product,
          timelineValue: parseFloat(reel.timeline[product.id]) || 0,
        }))
        .sort((a, b) => a.timelineValue - b.timelineValue);

      const clips = sortedProducts.map((product, index) => {
        const startOffset = Math.round(product.timelineValue);
        const endOffset =
          (index < sortedProducts.length - 1
            ? Math.round(sortedProducts[index + 1].timelineValue)
            : durationInSeconds) - 1;

        return {
          "@type": "Clip",
          name: product.title,
          url: `${basicInfo.contentUrl}?time=${startOffset}`,
          startOffset: startOffset,
          endOffset: Math.max(endOffset, 0), // Ensure endOffset doesn't go negative
        };
      });

      basicInfo.hasPart = clips;
    }

    return basicInfo;
  };

  return (
    <>
      <Helmet>
        {currentReel && (
          <>
            <title>{getPageTitle()}</title>
            <meta name="description" content={getPageDescription()} />
            <script type="application/ld+json">
              {JSON.stringify(getStructuredData(currentReel))}
            </script>
          </>
        )}
      </Helmet>
      {!isPlaying && !reelState && !isbot(navigator.userAgent) && <PreLoader />}
      <div className="reels-swiper" style={{ height: viewportHeight }}>
        <Swiper
          direction={"vertical"}
          className="reel-swiper"
          slidesPerView={1}
          onSlideChange={handleSlideChange}
          modules={[Mousewheel, Virtual]}
          mousewheel={true}
          virtual
        >
          {currentReels.map((reel, index) => (
            <SwiperSlide key={index} virtualIndex={index}>
              {({ isActive }) => (
                <Reel
                  swipeUp={index === 0}
                  data={reel}
                  isActive={isActive}
                  handleOnDataLoad={handleIsPlaying}
                  frameRef={frameRef}
                  onCommentMenuClick={handleCommentMenuClick}
                  onShopMenuClick={handleShopMenuClick}
                  onShareMenuClick={handleShareMenuClick}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="menu-container" ref={divEl}>
        <Menu
          id="menu-reel-comment"
          menuBoxBottom
          mediumRounded
          menuActive={commentMenuActive}
          height={370}
        >
          {currentReel ? (
            <DiscussionEmbed
              shortname={process.env.REACT_APP_DISQUS_KEY}
              config={{
                url: window.location.href,
                identifier: currentReel.shortId,
                title: currentReel.title,
              }}
            />
          ) : null}
        </Menu>
        <Menu
          id="menu-reel-shop"
          menuBoxBottom
          mediumRounded
          menuActive={shopMenuActive}
          height="calc(100% - 100px)"
        >
          <button onClick={closeReelMenus} className="menu-close-button">
            <i className="fa fa-times-circle" />
          </button>
          {currentProduct && (
            <div className="shoppable-current-product">
              <div className="product-gallery">
                <Swiper
                  style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                    "--swiper-navigation-size": "25px",
                  }}
                  loop={true}
                  spaceBetween={10}
                  navigation={true}
                  thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  {[
                    currentProduct.featured_image,
                    ...currentProduct.images,
                  ].map((image, index) => (
                    <SwiperSlide key={index}>
                      <img src={image} alt={currentProduct.title} />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <Swiper
                  onSwiper={setThumbsSwiper}
                  loop={true}
                  spaceBetween={2}
                  slidesPerView={4}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper"
                >
                  {[
                    currentProduct.featured_image,
                    ...currentProduct.images,
                  ].map((image, index) => (
                    <SwiperSlide key={index}>
                      <img src={image} alt={currentProduct.title} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <h6 className="mt-3" style={{ lineHeight: 1.5 }}>
                {currentProduct.title}
              </h6>
              <h6 className="mt-3">Description:</h6>
              <div style={{ marginBottom: 80 }}>
                <FormattedContent content={currentProduct.desc} />
              </div>
            </div>
          )}
        </Menu>

        {showBuyBtn && currentProduct && (
          <DropdownButton
            id="dropdown-affiliate-button"
            title="Buy Now"
            className="shoppable-buy-now"
            flip={true}
            variant="theme"
          >
            {currentProduct.affiliate_links.map((afl, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  href={afl.url}
                  target="_blank"
                  style={{ textTransform: "capitalize", textAlign: "center" }}
                >
                  On {afl.affiliate_company}
                  {"   "}
                  <i className="fa-solid fa-up-right-from-square"></i>
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
        )}

        <Menu
          id="menu-reel-share"
          menuBoxBottom
          mediumRounded
          menuActive={shareMenuActive}
          height={370}
        >
          {!isNativeShareAvailable && (
            <ShareMenu handleOnClose={closeReelMenus} />
          )}
        </Menu>
      </div>
    </>
  );
}

export default ReelList;
