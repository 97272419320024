import { create } from "zustand";

const useGlobalStore = create((set, get) => ({
  showPageTitle: true,
  scrollPositions: {},

  setShowPageTitle: (value) => set({ showPageTitle: value }),

  setScrollPosition: (key, position) =>
    set((state) => ({
      scrollPositions: { ...state.scrollPositions, [key]: position },
    })),

  getScrollPosition: (key) => get().scrollPositions[key] || 0,
}));

export default useGlobalStore;
