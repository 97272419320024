import { createContext } from "react";
import useDarkMode from "../hooks/useDarkMode";

const ThemeContext = createContext({
  theme: "dark-mode",
  toggleTheme: () => {},
});

export function ThemeProvider({ children }) {
  const { theme, toggleTheme } = useDarkMode();

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export default ThemeContext;
