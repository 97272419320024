import { create } from "zustand";

const useFeedbackStore = create((set) => ({
  feedbacks: {},
  setFeedback: (videoId, feedback) =>
    set((state) => ({
      feedbacks: {
        ...state.feedbacks,
        [videoId]: feedback,
      },
    })),
}));

export default useFeedbackStore;
