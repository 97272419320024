import { useState, useEffect, useRef } from "react";

const pwaName = "ugcfeed";
function useDarkMode() {
  const [theme, setTheme] = useState(
    localStorage.getItem(`${pwaName}-Theme`) || "dark-mode"
  );
  const darkModeMediaQueryRef = useRef(null);
  const lightModeMediaQueryRef = useRef(null);

  useEffect(() => {
    function removeTransitions() {
      const elements = document.querySelectorAll(
        ".btn, .header, #footer-bar, .menu-box, .menu-active"
      );
      elements.forEach((el) => {
        el.style.transition = "all 0s ease";
      });
    }

    function addTransitions() {
      const elements = document.querySelectorAll(
        ".btn, .header, #footer-bar, .menu-box, .menu-active"
      );
      elements.forEach((el) => {
        el.style.transition = "";
      });
    }

    function activateDarkMode() {
      document.body.classList.add("theme-dark");
      document.body.classList.remove("theme-light", "detect-theme");
      localStorage.setItem(`${pwaName}-Theme`, "dark-mode");
      removeTransitions();
      setTimeout(addTransitions, 350);
    }

    function activateLightMode() {
      document.body.classList.add("theme-light");
      document.body.classList.remove("theme-dark", "detect-theme");
      localStorage.setItem(`${pwaName}-Theme`, "light-mode");
      removeTransitions();
      setTimeout(addTransitions, 350);
    }

    function setColorScheme() {
      const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)")
        .matches;
      const isLightMode = window.matchMedia("(prefers-color-scheme: light)")
        .matches;

      if (isDarkMode) activateDarkMode();
      if (isLightMode) activateLightMode();
    }

    darkModeMediaQueryRef.current = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    lightModeMediaQueryRef.current = window.matchMedia(
      "(prefers-color-scheme: light)"
    );

    darkModeMediaQueryRef.current.addListener(
      (e) => e.matches && activateDarkMode()
    );
    lightModeMediaQueryRef.current.addListener(
      (e) => e.matches && activateLightMode()
    );

    if (theme === "dark-mode") {
      activateDarkMode();
    } else if (theme === "light-mode") {
      activateLightMode();
    } else if (theme === "detect-theme") {
      setColorScheme();
    }

    return () => {
      darkModeMediaQueryRef.current.removeListener(
        (e) => e.matches && activateDarkMode()
      );
      lightModeMediaQueryRef.current.removeListener(
        (e) => e.matches && activateLightMode()
      );
    };
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => {
      if (prevTheme === "detect-theme") {
        const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)")
          .matches;
        return isDarkMode ? "light-mode" : "dark-mode";
      }
      if (prevTheme === "dark-mode") return "light-mode";
      if (prevTheme === "light-mode") return "dark-mode";
      return "detect-theme";
    });
  };

  return { theme, toggleTheme };
}

export default useDarkMode;
