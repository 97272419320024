function PreLoader() {
  return (
    <div
      id="preloader"
      style={{
        zIndex: 99999,
      }}
    >
      <div className="spinner-border color-highlight" role="status"></div>
    </div>
  );
}

export default PreLoader;
