function ActionButton({
  type,
  disabled = false,
  active = false,
  loading = false,
  onClick,
  iconClassName,
  text,
  btnStyle = {},
  actionTxtstyle = {},
}) {
  return (
    <div className="action-container">
      <button
        disabled={disabled}
        className={`action-button ${type} ${active ? "active" : ""}`}
        onClick={onClick}
        style={btnStyle}
      >
        {loading ? (
          <i className="fa fa-spinner fa-spin" />
        ) : (
          <i className={iconClassName} />
        )}
      </button>
      <div className="action-text" style={actionTxtstyle}>
        {text}
      </div>
    </div>
  );
}

export default ActionButton;
