import Select from "react-select";
import useThemeStyle from "./useThemeStyle";

function SearchSortSelect({ selectedSort, onSortChange }) {
  const handlesortChange = (selectedOption) => {
    onSortChange(selectedOption);
  };

  const themeStyle = useThemeStyle();

  const sortOptions = [
    { label: "Relevance", value: "relevance" },
    { label: "Newest", value: "newest" },
    { label: "Oldest", value: "oldest" },
    { label: "Most Viewed", value: "most_viewed" },
    { label: "Least Viewed", value: "least_viewed" },
    { label: "Most Liked", value: "most_liked" },
    { label: "Most Disliked", value: "most_disliked" },
    { label: "Shortest", value: "shortest" },
    { label: "Longest", value: "longest" },
    { label: "A to Z", value: "a_to_z" },
    { label: "Z to A", value: "z_to_a" },
    { label: "Featured", value: "featured" },
    { label: "Latest Edits", value: "latest_edits" },
    { label: "Mix It Up", value: "mix_it_up" },
  ];

  const selectedSortOption =
    sortOptions.find((option) => option.value === selectedSort) || null;

  return (
    <Select
      value={selectedSortOption}
      onChange={handlesortChange}
      options={sortOptions}
      placeholder="Sort by:"
      className="mb-3"
      styles={themeStyle}
    />
  );
}

export default SearchSortSelect;
