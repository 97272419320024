function ShareMenu({ handleOnClose }) {
  const shareTitle = encodeURIComponent(document.title);
  const shareLink = encodeURIComponent(window.location.href);

  const shareToFacebook = `https://www.facebook.com/sharer/sharer.php?u=${shareLink}`;
  const shareToTwitter = `http://twitter.com/share?text=${shareTitle}&url=${shareLink}`;
  const shareToLinkedIn = `https://www.linkedin.com/shareArticle?mini=true&url=${shareLink}&title=${shareTitle}`;
  const shareToWhatsApp = `whatsapp://send?text=${shareLink}`;
  const shareToMail = `mailto:?subject=${shareTitle}&body=${shareLink}`;

  return (
    <>
      <div className="menu-title">
        <p className="color-highlight">Tap a link to</p>
        <h1>Share</h1>
        <button onClick={handleOnClose} className="close-menu">
          <i className="fa fa-times-circle" />
        </button>
      </div>
      <div className="divider divider-margins mt-3 mb-0">
        <div className="list-group list-custom-small list-icon-0">
          <a
            href={shareToFacebook}
            target="_blank"
            rel="noopener noreferrer"
            className="external-link"
          >
            <i className="fab fa-facebook-f font-12 bg-facebook color-white shadow-l rounded-s"></i>
            <span>Facebook</span>
            <i className="fa fa-angle-right pr-1"></i>
          </a>
          <a
            href={shareToTwitter}
            target="_blank"
            rel="noopener noreferrer"
            className="external-link"
          >
            <i className="fab fa-twitter font-12 bg-twitter color-white shadow-l rounded-s"></i>
            <span>Twitter</span>
            <i className="fa fa-angle-right pr-1"></i>
          </a>
          <a
            href={shareToLinkedIn}
            target="_blank"
            rel="noopener noreferrer"
            className="external-link"
          >
            <i className="fab fa-linkedin-in font-12 bg-linkedin color-white shadow-l rounded-s"></i>
            <span>LinkedIn</span>
            <i className="fa fa-angle-right pr-1"></i>
          </a>
          <a
            href={shareToWhatsApp}
            target="_blank"
            rel="noopener noreferrer"
            className="external-link"
          >
            <i className="fab fa-whatsapp font-12 bg-whatsapp color-white shadow-l rounded-s"></i>
            <span>WhatsApp</span>
            <i className="fa fa-angle-right pr-1"></i>
          </a>
          <a href={shareToMail} className="external-link border-0">
            <i className="fa fa-envelope font-12 bg-mail color-white shadow-l rounded-s"></i>
            <span>Email</span>
            <i className="fa fa-angle-right pr-1"></i>
          </a>
        </div>
      </div>
    </>
  );
}

export default ShareMenu;
