import { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Masonry from "react-layout-masonry";
import debounce from "lodash/debounce";
import { Helmet } from "react-helmet-async";
import axios from "../utils/axiosConfig";
import SimpleReelCard from "../components/cards/SimpleReelCard";
import { SimpleLoader } from "../components/utils/SimpleLoader";
import InfoCard from "../components/cards/InfoCard";
import SearchSortSelect from "../components/selects/SearchSortSelect";
import useReelsStore from "../stores/reelsStore";

import useScrollToReel from "../hooks/useScrollToReel";

import { useHeaderContext } from "../contexts/HeaderContext";

function Search({ frameRef }) {
  const { setPageTitle } = useHeaderContext();

  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const queryParam = searchParams.get("q") || "";
  const sortParam = searchParams.get("sort") || "relevance";

  const [searchQuery, setSearchQuery] = useState(queryParam);
  const [sortBy, setSortBy] = useState(sortParam);

  const { setReelsForPath, getReelsForPath } = useReelsStore((state) => ({
    setReelsForPath: state.setReelsForPath,
    getReelsForPath: state.getReelsForPath,
  }));

  const currentPath = location.pathname + (location.search || "");
  const { reels, nextPageToken } = getReelsForPath(currentPath);

  const [cardDimensions, setCardDimensions] = useState({ width: 0, height: 0 });
  const [touchedCardId, setTouchedCardId] = useState(null);
  const [isTouchMoved, setIsTouchMoved] = useState(false);
  const [loadingReels, setLoadingReels] = useState(false);

  const fetchSearchResults = useCallback(
    async (query, sortOrder) => {
      if (query.length < 1) return;

      try {
        setLoadingReels(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/search`,
          {
            params: {
              ...(nextPageToken && { next_page_token: nextPageToken }),
              q: query,
              sort: sortOrder,
            },
          }
        );

        const {
          data: fetchedReels,
          next_page_token: newNextPageToken,
        } = response.data;

        setReelsForPath(
          `/search?q=${encodeURIComponent(query)}&sort=${encodeURIComponent(
            sortOrder
          )}`,
          fetchedReels,
          newNextPageToken
        );
      } catch (error) {
        console.error("Error fetching search results:", error);
      } finally {
        setLoadingReels(false);
      }
    },
    [setReelsForPath, nextPageToken]
  );

  const setDynamicDimensions = useCallback(() => {
    const gutter = 8;
    const numberOfColumns = 2;

    const containerWidth =
      frameRef && frameRef.current
        ? frameRef.current.offsetWidth
        : window.innerWidth;

    const cardWidth =
      (containerWidth - 30 - (numberOfColumns - 1) * gutter) / numberOfColumns;
    const cardHeight = (16 / 9) * cardWidth;

    setCardDimensions({ width: cardWidth, height: cardHeight });
  }, [frameRef]);

  const scrollId = location.state?.scrollId;
  useScrollToReel(frameRef, scrollId);

  useEffect(() => {
    setPageTitle("Search");
  }, [setPageTitle]);

  useEffect(() => {
    if (reels.length === 0 && searchQuery.length >= 1) {
      fetchSearchResults(searchQuery, sortBy);
    }
  }, [currentPath]); // eslint-disable-line react-hooks/exhaustive-deps

  const observer = useRef();
  const lastReelElementRef = useCallback(
    (node) => {
      if (loadingReels) return;

      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && nextPageToken !== null) {
          fetchSearchResults(searchQuery, sortBy);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loadingReels, nextPageToken, searchQuery, sortBy, fetchSearchResults]
  );

  useEffect(() => {
    setDynamicDimensions();
    window.addEventListener("resize", setDynamicDimensions);
    return () => {
      window.removeEventListener("resize", setDynamicDimensions);
    };
  }, [setDynamicDimensions]);

  useEffect(() => {
    const handleTouchMove = () => {
      setIsTouchMoved(true);
    };

    window.addEventListener("touchmove", handleTouchMove);

    return () => {
      window.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  useEffect(() => {
    if (isTouchMoved) {
      setIsTouchMoved(false);
    }
  }, [isTouchMoved]);

  const debouncedFetchSearchResults = useMemo(
    () =>
      debounce((val) => {
        navigate(val);
      }, 300),
    [navigate]
  );

  const handleSearchChange = useCallback(
    (e) => {
      const query = e.target.value;
      const queryString = `?q=${encodeURIComponent(
        query
      )}&sort=${encodeURIComponent("relevance")}`;
      setSearchQuery(query);
      setSortBy("relevance");
      debouncedFetchSearchResults(queryString);
    },
    [debouncedFetchSearchResults]
  );

  const handleSortChange = (e) => {
    const newSortOrder = e.value;
    setSortBy(newSortOrder);
    navigate(
      `?q=${encodeURIComponent(searchQuery)}&sort=${encodeURIComponent(
        newSortOrder
      )}`
    );
  };

  const renderedReels = reels.map((reel) => (
    <SimpleReelCard
      key={reel.id}
      reel={reel}
      height={cardDimensions.height}
      width={cardDimensions.width}
      onClick={(e) => {
        e.preventDefault();
        navigate(`/peeks/${reel.short_id}`, {
          state: {
            referrer: `/search?q=${encodeURIComponent(
              searchQuery
            )}&sort=${encodeURIComponent(sortBy)}`,
            reel,
          },
        });
      }}
      isTouchMoved={isTouchMoved}
      touchedCardId={touchedCardId}
      setTouchedCardId={setTouchedCardId}
    />
  ));

  if (loadingReels) {
    for (let i = 0; i < 4; i++) {
      renderedReels.push(
        <div
          className="card mx-0 mb-2 card-style"
          key={`loader-${i}`}
          style={{
            height: cardDimensions.height,
            width: cardDimensions.width,
          }}
        >
          <SimpleLoader
            height={cardDimensions.height}
            width={cardDimensions.width}
          />
        </div>
      );
    }
  }

  const getPageTitle = () => {
    const sortDescription =
      sortBy && sortBy !== "relevance" ? ` - Sorted by ${sortBy}` : "";
    return searchQuery
      ? `Search Results for "${searchQuery}"${sortDescription} | Quik Peeks`
      : "Search | Quik Peeks";
  };

  const getPageDescription = () => {
    const sortDescription =
      sortBy && sortBy !== "relevance" ? `, sorted by ${sortBy}` : "";
    return searchQuery
      ? `Explore search results for '${searchQuery}' on Quik Peeks${sortDescription}. Discover unique user-generated content in our peeks.`
      : "Discover unique and interesting user-generated content in our peeks on Quik Peeks. Start your search now.";
  };

  return (
    <>
      <Helmet>
        <title>{getPageTitle()}</title>
        <meta name="description" content={getPageDescription()} />
      </Helmet>
      <div className="page-content">
        <div className="content mb-2">
          <div className="search-box search-dark shadow-sm border-0 mt-4 bg-theme rounded-sm bottom-0">
            <i className="fa fa-search ms-1" />
            <input
              type="search"
              className="border-0"
              placeholder="Searching for something? Try 'lamp'"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        {(reels.length > 0 || searchQuery.length > 0) && (
          <div className="content mb-2 mt-0">
            <SearchSortSelect
              selectedSort={sortBy}
              onSortChange={handleSortChange}
            />
          </div>
        )}

        <div className="content mb-4">
          <Masonry columns={2} gap={8}>
            {renderedReels}
            <div ref={lastReelElementRef} />
          </Masonry>
          {reels.length === 0 ? (
            searchQuery.length > 0 ? (
              <InfoCard
                centered
                heading="No Results Found"
                subHeading={`We couldn't find any results for your query: "${searchQuery}". Try different keywords.`}
              />
            ) : (
              <InfoCard
                centered
                heading="Start Your Search"
                subHeading="Type in a keyword or tag to search for peeks."
              />
            )
          ) : (
            nextPageToken == null && (
              <InfoCard
                centered
                heading="No more results to show."
                subHeading="Looks like we've reached the end of the carrot patch!"
              />
            )
          )}
        </div>
      </div>
    </>
  );
}

export default Search;
