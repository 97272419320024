import { useState, useEffect, useRef, useContext } from "react";
import {
  Route,
  Routes,
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import { isBrowser } from "react-device-detect";
import { DeviceFrameset } from "react-device-frameset";
import { HelmetProvider } from "react-helmet-async";
import "react-device-frameset/styles/marvel-devices.min.css";

import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";

import { ThemeProvider } from "./contexts/ThemeContext";
import ThemeContext from "./contexts/ThemeContext";
import { HeaderProvider } from "./contexts/HeaderContext";
import { MuteProvider } from "./contexts/MuteContext";

import useGlobalStore from "./stores/globalStore";

import Home from "./pages/Home";
import Feed from "./pages/Feed";
import Reels from "./pages/Reels";
import ReelList from "./pages/ReelList";
import Search from "./pages/Search";
import SiteInfo from "./pages/SiteInfo";
import Category from "./pages/Category";

function Layout({ children, frameRef, sort }) {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <>
      <Header
        theme={theme}
        handleToggleTheme={toggleTheme}
        frameRef={frameRef}
        sort={sort}
      />
      {children}
      <Footer />
    </>
  );
}

function AppContent({ frameRef }) {
  const location = useLocation();

  useEffect(() => {
    const pathKey = location.pathname + (location.search || "");
    const currentRef = frameRef?.current;

    const handleScroll = () => {
      const currentScrollPosition = currentRef
        ? currentRef.scrollTop
        : window.scrollY;
      useGlobalStore
        .getState()
        .setScrollPosition(pathKey, currentScrollPosition);
    };

    const restoreScrollPosition = () => {
      const savedScrollPosition =
        useGlobalStore.getState().getScrollPosition(pathKey) || 0;
      if (currentRef) {
        currentRef.scrollTo({
          top: savedScrollPosition,
          behavior: "auto",
        });
      } else {
        window.scrollTo({
          top: savedScrollPosition,
          behavior: "auto",
        });
      }
    };

    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
      setTimeout(restoreScrollPosition, 0);
    } else {
      window.addEventListener("scroll", handleScroll);
      setTimeout(restoreScrollPosition, 0);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      } else {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, [location.pathname, location.search, frameRef]);

  return (
    <Routes>
      <Route
        path="*"
        element={
          <Layout frameRef={frameRef}>
            <Routes>
              <Route
                path="/"
                element={<Home frameRef={frameRef} key={location.key} />}
              />
              <Route
                path="privacy-policy"
                element={
                  <SiteInfo
                    frameRef={frameRef}
                    title="Privacy Policy"
                    desc="Learn about our privacy practices."
                    slug="privacy-policy"
                  />
                }
              />
              <Route
                path="terms-of-service"
                element={
                  <SiteInfo
                    frameRef={frameRef}
                    title="Terms of Service"
                    desc="Review our terms of service."
                    slug="terms-of-service"
                  />
                }
              />
              <Route
                path="contact-us"
                element={
                  <SiteInfo
                    frameRef={frameRef}
                    title="Contact Us"
                    desc="Get in touch with us."
                    slug="contact-us"
                  />
                }
              />
              <Route
                path="about-us"
                element={
                  <SiteInfo
                    frameRef={frameRef}
                    title="About Us"
                    desc="Learn more about Quik Peeks."
                    slug="about-us"
                  />
                }
              />
              <Route path="search" element={<Search frameRef={frameRef} />} />
              <Route path="peeks/*" element={<Reels />} />
            </Routes>
          </Layout>
        }
      />
      <Route
        path="categories/:slug"
        element={<Category frameRef={frameRef} />}
      />
      <Route path="/feed/:sort" element={<Feed frameRef={frameRef} />} />
      <Route path="peeks/:shortId" element={<ReelList frameRef={frameRef} />} />
    </Routes>
  );
}

function FrameOrContent({ frameRef, isLargerScreen, scaleValue, isMounted }) {
  return isLargerScreen ? (
    <div className="mobile-frame">
      <DeviceFrameset device="iPhone X" zoom={scaleValue}>
        <div className="app-content" ref={frameRef}>
          <div id="page">
            {isMounted && <AppContent key="largeScreen" frameRef={frameRef} />}
          </div>
        </div>
      </DeviceFrameset>
    </div>
  ) : (
    <div id="page">
      <AppContent key="smallScreen" />
    </div>
  );
}

function App() {
  const frameRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);
  const [isLargerScreen, setIsLargerScreen] = useState(isBrowser);
  const [scaleValue, setScaleValue] = useState(1);

  useEffect(() => {
    const calculateScale = () => {
      setIsLargerScreen(window.innerWidth > 480);
      const desiredHeight = 812;
      const marginTotal = 150;
      const availableHeight = window.innerHeight - marginTotal;
      const scale = availableHeight / desiredHeight;
      setScaleValue(scale);
    };
    calculateScale();
    window.addEventListener("resize", calculateScale);
    return () => window.removeEventListener("resize", calculateScale);
  }, []);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const router = createBrowserRouter([
    {
      path: "*",
      element: (
        <FrameOrContent
          frameRef={frameRef}
          isLargerScreen={isLargerScreen}
          scaleValue={scaleValue}
          isMounted={isMounted}
        />
      ),
    },
  ]);

  return (
    <ThemeProvider>
      <HelmetProvider>
        <HeaderProvider>
          <MuteProvider>
            <RouterProvider router={router} />
          </MuteProvider>
        </HeaderProvider>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export { Layout };

export default App;
