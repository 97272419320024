import { Helmet } from "react-helmet-async";
import { useHeaderContext } from "../contexts/HeaderContext";
import { useEffect } from "react";

function PrivacyPolicy() {
  return (
    <>
      <h2 className="text-center mb-4">Privacy Policy</h2>

      <h4>Introduction</h4>
      <p>
        At <strong>quikpeeks.com</strong>, accessible from
        <strong> https://quikpeeks.com</strong>, one of our main priorities is
        the privacy of our visitors. This Privacy Policy document contains types
        of information that is collected and recorded by
        <strong> quikpeeks.com</strong> and how we use it.
      </p>

      <p>
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to contact us.
      </p>

      <p>
        This Privacy Policy applies only to our online activities and is valid
        for visitors to our website with regards to the information that they
        shared and/or collect in <strong> quikpeeks.com</strong>. This policy is
        not applicable to any information collected offline or via channels
        other than this website.
      </p>

      <h4>Consent</h4>
      <p>
        By using our website, you hereby consent to our Privacy Policy and agree
        to its terms.
      </p>

      <h4>Information we collect</h4>
      <p>
        The personal information that you are asked to provide, and the reasons
        why you are asked to provide it, will be made clear to you at the point
        we ask you to provide your personal information.
      </p>

      <p>
        If you contact us directly, we may receive additional information about
        you such as your name, email address, phone number, the contents of the
        message and/or attachments you may send us, and any other information
        you may choose to provide.
      </p>

      <h4>How we use your information</h4>
      <p>We use the information we collect in various ways, including to:</p>
      <ul>
        <li>Provide, operate, and maintain our website</li>
        <li>Improve, personalize, and expand our website</li>
        <li>Understand and analyze how you use our website</li>
        <li>Develop new products, services, features, and functionality</li>
        <li>
          Communicate with you, either directly or through one of our partners,
          including for customer service, to provide you with updates and other
          information relating to the website, and for marketing and promotional
          purposes
        </li>
        <li>Send you emails</li>
        <li>Find and prevent fraud</li>
      </ul>
      <h4>Log Files</h4>
      <p>
        <strong> quikpeeks.com</strong> follows a standard procedure of using
        log files. These files log visitors when they visit websites. All
        hosting companies do this and a part of hosting services' analytics. The
        information collected by log files include internet protocol (IP)
        addresses, browser type, Internet Service Provider (ISP), date and time
        stamp, referring/exit pages, and possibly the number of clicks. These
        are not linked to any information that is personally identifiable. The
        purpose of the information is for analyzing trends, administering the
        site, tracking users' movement on the website, and gathering demographic
        information.
      </p>
      <h4>Cookies and Web Beacons</h4>
      <p>
        Like any other website, <strong> quikpeeks.com</strong> uses 'cookies'.
        These cookies are used to store information including visitors'
        preferences, and the pages on the website that the visitor accessed or
        visited. The information is used to optimize the users' experience by
        customizing our web page content based on visitors' browser type and/or
        other information.
      </p>
      <p>
        For more general information on cookies, please read{" "}
        <a
          href="https://www.cookieconsent.com/what-are-cookies/"
          rel="noopener noreferrer nofollow"
        >
          "What Are Cookies" from Cookie Consent
        </a>
        .
      </p>
      <h4>Third Party Privacy Policies</h4>
      <p>
        <strong> quikpeeks.com's</strong> Privacy Policy does not apply to other
        advertisers or websites. Thus, we are advising you to consult the
        respective Privacy Policies of these third-party ad servers for more
        detailed information. It may include their practices and instructions
        about how to opt-out of certain options.
      </p>
      <p>
        You can choose to disable cookies through your individual browser
        options. To know more detailed information about cookie management with
        specific web browsers, it can be found at the browsers' respective
        websites.
      </p>
      <h4>CCPA Privacy Rights (Do Not Sell My Personal Information)</h4>
      <p>
        Under the CCPA, among other rights, California consumers have the right
        to:
      </p>
      <p>
        Request that a business that collects a consumer's personal data
        disclose the categories and specific pieces of personal data that a
        business has collected about consumers.
      </p>
      <p>
        Request that a business delete any personal data about the consumer that
        a business has collected.
      </p>
      <p>
        Request that a business that sells a consumer's personal data, not sell
        the consumer's personal data.
      </p>
      <p>
        If you make a request, we have one month to respond to you. If you would
        like to exercise any of these rights, please contact us.
      </p>
      <h4>GDPR Data Protection Rights</h4>
      <p>
        We would like to make sure you are fully aware of all of your data
        protection rights. Every user is entitled to the following:
      </p>
      <p>
        The right to access – You have the right to request copies of your
        personal data. We may charge you a small fee for this service.
      </p>
      <p>
        The right to rectification – You have the right to request that we
        correct any information you believe is inaccurate. You also have the
        right to request that we complete the information you believe is
        incomplete.
      </p>
      <p>
        The right to erasure – You have the right to request that we erase your
        personal data, under certain conditions.
      </p>
      <p>
        The right to restrict processing – You have the right to request that we
        restrict the processing of your personal data, under certain conditions.
      </p>
      <p>
        The right to object to processing – You have the right to object to our
        processing of your personal data, under certain conditions.
      </p>
      <p>
        The right to data portability – You have the right to request that we
        transfer the data that we have collected to another organization, or
        directly to you, under certain conditions.
      </p>
      <p>
        If you make a request, we have one month to respond to you. If you would
        like to exercise any of these rights, please contact us.
      </p>
      <h4>Children's Information</h4>
      <p>
        Another part of our priority is adding protection for children while
        using the internet. We encourage parents and guardians to observe,
        participate in, and/or monitor and guide their online activity.
      </p>
      <p>
        <strong> quikpeeks.com</strong> does not knowingly collect any Personal
        Identifiable Information from children under the age of 13. If you think
        that your child provided this kind of information on our website, we
        strongly encourage you to contact us immediately and we will do our best
        efforts to promptly remove such information from our records.
      </p>
    </>
  );
}

function TermsOfService() {
  return (
    <>
      <h2 className="text-center mb-4">Terms of Use</h2>

      <h4>Overview</h4>
      <p>
        At <strong>quikpeeks.com</strong> we recognize that privacy of your
        personal information is important. Here is information on what types of
        personal information we receive and collect when you use and visit
        <strong> quikpeeks.com</strong>, and how we safeguard your information.
        We never sell your personal information to third parties.
      </p>
      <h4>Log Files</h4>
      <p>
        As with most other websites, we collect and use the data contained in
        log files. The information in the log files include your IP (internet
        protocol) address, your ISP (internet service provider, such as AOL or
        Shaw Cable), the browser you used to visit our site (such as Internet
        Explorer or Firefox), the time you visited our site and which pages you
        visited throughout our site.
      </p>
      <h4>Cookies and Web Beacons</h4>
      <p>
        We do use cookies to store information, such as your personal
        preferences when you visit our site. This could include only showing you
        a popup once in your visit, or the ability to login to some of our
        features.
      </p>
      <p>
        We also use third party advertisements on <strong>quikpeeks.com</strong>{" "}
        to support our site. Some of these advertisers may use technology such
        as cookies and web beacons when they advertise on our site, which will
        also send these advertisers information including your IP address, your
        ISP , the browser you used to visit our site, and in some cases, whether
        you have Flash installed. This is generally used for geotargeting
        purposes (showing New York real estate ads to someone in New York, for
        example) or showing certain ads based on specific sites visited (such as
        showing cooking ads to someone who frequents cooking sites).
      </p>
      <p>
        You can chose to disable or selectively turn off our cookies or
        third-party cookies in your browser settings, or by managing preferences
        in programs such as Norton Internet Security. However, this can affect
        how you are able to interact with our site as well as other websites.
        This could include the inability to login to services or programs, such
        as logging into forums or accounts.
      </p>
      <h4>Indemnification</h4>
      <p>
        By accepting our Terms of Use, you agree to indemnify and otherwise hold
        harmless <strong>quikpeeks.com</strong>, its officers, employees,
        agents, subsidiaries, affiliates and other partners from any direct,
        indirect, incidental, special, consequential or exemplary damages
        resulting from i) your use of the <strong>quikpeeks.com</strong>{" "}
        Service; ii) unauthorized access to or alteration of your communications
        with or through the <strong>quikpeeks.com</strong> Service, or iii) any
        other matter relating to the <strong>quikpeeks.com</strong> Service.
      </p>
      <h4>Limitation of Liability</h4>
      <p>
        You must agree that <strong>quikpeeks.com</strong> shall not be liable
        to you for any reason. You must not seek any payment from{" "}
        <strong>quikpeeks.com</strong> for any reason. Governing Law The Terms
        of Use between you and <strong>quikpeeks.com</strong>
        will be governed by and construed in accordance with the laws of the
        State of New York without regard to conflict of laws principles.
      </p>
      <h4>Non Endorsement</h4>
      <p>
        <strong>quikpeeks.com</strong> does not sponsor, recommend, or endorse
        any Third Party Content Creators, Contributors, or their offerings that
        are accessible through our platform. Our site facilitates communication
        between Third Party Content Creators and potential users of associated
        content and services.
      </p>

      <p>
        <strong>quikpeeks.com</strong> has no control over the quality,
        accuracy, or completeness of the content created or curated by Third
        Party Content Creators. The content on our platform includes
        descriptions and information provided directly by these third parties.
        Consequently, <strong>quikpeeks.com</strong> does not represent or
        warrant the accuracy or completeness of such descriptions and
        information. As a result, <strong>quikpeeks.com</strong> has no control
        over the truth, accuracy, or reliability of the content provided.
      </p>

      <p>
        We are not responsible for ensuring that users and Third Party Content
        Creators engage as intended or expected. <strong>quikpeeks.com</strong>{" "}
        does not guarantee that users will find the content they seek or achieve
        any specific outcome through the use of our platform. Decisions based on
        content obtained from our platform are significant and should not be
        based solely on the information provided by any Third Party Content
        Creator.
      </p>

      <p>
        <strong>quikpeeks.com</strong> does not review the credentials,
        qualifications, or the standing of any Third Party Content Creators.
        Therefore, <strong>quikpeeks.com </strong>
        makes no representation regarding the status, reputation, or reliability
        of any Third Party Content Creator. When considering any content, users
        should conduct their own investigation and verify all claims made by
        Third Party Content Creators. Users are encouraged to exercise caution
        when reviewing any information submitted by Third Party Content
        Creators.
      </p>

      <p>
        You understand and agree that <strong>quikpeeks.com</strong> shall not
        be liable to you or any third party for any content offered, created, or
        curated by any Third Party Content Creator.
      </p>
    </>
  );
}

function AboutUs() {
  return (
    <>
      <h2 className="text-center mb-4">About Us</h2>
      <p>
        Welcome to <strong>Quik Peeks</strong> – the future of online browsing,
        where speed meets convenience, and discovery meets innovation. Our
        journey began with a simple yet powerful idea: to revolutionize the way
        people explore and shop in the digital age.
      </p>

      <h4>Our Vision</h4>
      <p>
        In a world where time is precious and attention spans are shortening, we
        envisioned a platform that cuts through the noise. Our vision was to
        create a space where users could quickly access the essence of a product
        or experience through engaging, bite-sized videos.{" "}
        <strong>Quik Peeks</strong> is not just a platform; it's a new way of
        interacting with the digital marketplace.
      </p>

      <h4>The Birth of Quik Peeks</h4>
      <p>
        Our story began in a small, bustling office space, where a group of
        creative minds gathered with a shared dream. We looked at the
        traditional online shopping and browsing experience and asked, "How can
        we make this faster, easier, and more enjoyable?" The answer lay in
        leveraging the power of video content and intuitive design to create a
        seamless and interactive user experience.
      </p>

      <h4>What We Offer</h4>
      <p>
        At <strong>Quik Peeks</strong>, we offer an array of short, vertical
        video previews – or "peeks" – that showcase the best of products and
        experiences. Our platform is designed to be as intuitive as swiping
        through your favorite photos. Each peek is a window into a world of
        possibilities, providing key information in a format that’s both
        informative and visually appealing.
      </p>

      <h4>Empowering the Modern Consumer</h4>
      <p>
        We understand that the modern consumer values efficiency and instant
        access to information. <strong>Quik Peeks</strong> caters to this need
        by providing a platform where discovery, entertainment, and shopping
        converge. Our users enjoy the freedom to explore a diverse range of
        products and experiences without the need for in-depth research.
      </p>

      <h4>Join Us on Our Journey</h4>
      <p>
        We are more than just a platform; we are a community of explorers,
        trendsetters, and innovators. Join us on this exciting journey as we
        continue to redefine the digital browsing experience. At{" "}
        <strong>Quik Peeks</strong>, your next discovery is just a swipe away.
      </p>
    </>
  );
}

function ContactUs() {
  return (
    <>
      <h2 className="text-center mb-4">Contact Us</h2>

      <p>
        Welcome to <strong>quikpeeks.com</strong>! We value the feedback and
        inquiries of our users and are here to help you. Whether you have a
        question, suggestion, or need assistance with our service, we're here to
        listen and provide the support you need.
      </p>
      <p>
        If you're experiencing any issues, have queries about our content, or
        just want to give us feedback, please don't hesitate to reach out. Our
        dedicated support team is always ready to assist you with any concerns
        or questions you might have.
      </p>
      <p>
        <strong>How to Contact Us:</strong>
      </p>
      <p>
        You can easily get in touch with us by sending an email to{" "}
        <a href="mailto:support@quikpeeks.com">support@quikpeeks.com</a>. We
        strive to respond to all emails promptly, so you can expect a timely and
        helpful response from our team.
      </p>
      <p>
        <strong>What to Include in Your Email:</strong>
      </p>
      <p>
        To assist you efficiently, please include the following details in your
        email:
      </p>
      <ul>
        <li>Your full name and contact information</li>
        <li>A clear and concise description of your question or issue</li>
        <li>
          Any relevant details or context that would help us understand your
          needs better
        </li>
      </ul>
      <p>
        <strong>Feedback and Suggestions:</strong>
      </p>
      <p>
        Your feedback is invaluable to us. It helps us understand what we are
        doing well and where we can improve. If you have any suggestions on how
        we can enhance our service or content, please feel free to share them
        with us. We are always looking for ways to better serve our community
        and deliver a superior experience.
      </p>
      <p>
        <strong>Business Inquiries:</strong>
      </p>
      <p>
        For business-related inquiries, partnerships, or collaborations, please
        direct your email to the same address:{" "}
        <a href="mailto:support@quikpeeks.com">support@quikpeeks.com</a>. Our
        business development team will review your proposal and get back to you
        as soon as possible.
      </p>
      <p>
        <strong>Response Time:</strong>
      </p>
      <p>
        We are committed to providing a quick response to all our users.
        However, the response time may vary depending on the volume of inquiries
        we are handling. We appreciate your patience and understanding in this
        regard.
      </p>
      <p>
        At <strong>quikpeeks.com</strong>, we're dedicated to ensuring that your
        experience with our platform is smooth and enjoyable. Please reach out
        with any concerns or questions - we're here to help!
      </p>
    </>
  );
}

function SiteInfo({ title, desc, slug }) {
  const { setMainMenuActive } = useHeaderContext();

  useEffect(() => {
    setMainMenuActive(false);
  }, [setMainMenuActive, slug]);

  const renderContent = () => {
    switch (slug) {
      case "privacy-policy":
        return <PrivacyPolicy />;
      case "terms-of-service":
        return <TermsOfService />;
      case "contact-us":
        return <ContactUs />;
      case "about-us":
        return <AboutUs />;
      default:
        return null;
    }
  };

  return (
    <>
      <Helmet>
        <title>{title} | Quik Peeks</title>
        <meta name="description" content={desc} />
      </Helmet>
      <div className="page-content">
        <div className="card card-style">
          <div className="content">{renderContent()}</div>
        </div>
      </div>
    </>
  );
}

export default SiteInfo;
