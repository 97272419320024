import { createContext, useContext, useState } from "react";

const MuteContext = createContext();

export function useMute() {
  return useContext(MuteContext);
}

export function MuteProvider({ children }) {
  const [isMuted, setIsMuted] = useState(true);

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  return (
    <MuteContext.Provider value={{ isMuted, toggleMute, setIsMuted }}>
      {children}
    </MuteContext.Provider>
  );
}
