import { useState, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import useGlobalStore from "../../stores/globalStore";
import useScrollEffect from "../../hooks/useScrollEffect";
import useClickOutside from "../../hooks/useClickOutside";
import Menu from "./Menu";
import ShareMenu from "./ShareMenu";
import SiteLinks from "./SiteLinks";

import { useHeaderContext } from "../../contexts/HeaderContext";

function Header({ handleToggleTheme, frameRef, sort, theme }) {
  const { mainMenuActive, setMainMenuActive, pageTitle } = useHeaderContext();

  const location = useLocation();
  const navigate = useNavigate();
  const [shareMenuActive, setShareMenuActive] = useState(false);
  const [isNativeShareAvailable, setIsNativeShareAvailable] = useState(false);
  const showPageTitle = useGlobalStore((state) => state.showPageTitle);

  const divEl = useRef();

  const mainMenuEl1 = useRef();
  const mainMenuEl2 = useRef();

  const shareMenuEl1 = useRef();
  const shareMenuEl2 = useRef();

  const headerRef = useRef(null);
  const titleRef = useRef(null);

  useScrollEffect(headerRef, titleRef, frameRef);

  const closeMenus = useCallback(() => {
    setMainMenuActive(false);
    setShareMenuActive(false);
  }, [setMainMenuActive]);

  useClickOutside(
    [
      divEl,
      shareMenuEl1,
      ...(showPageTitle ? [shareMenuEl2, mainMenuEl1, mainMenuEl2] : []),
    ],
    closeMenus
  );

  const handleMainMenuClick = useCallback(() => {
    setShareMenuActive(false);
    setMainMenuActive((prev) => !prev);
  }, [setMainMenuActive]);

  const handleShareMenuClick = useCallback(() => {
    if (navigator.share) {
      const shareData = {
        title: document.title,
        url: window.location.href,
      };
      navigator.share(shareData).catch(console.error);
      setIsNativeShareAvailable(true);
    } else {
      setIsNativeShareAvailable(false);
      setMainMenuActive(false);
      setShareMenuActive((prev) => !prev);
    }
  }, [setMainMenuActive]);

  const handleBackButtonClick = () => {
    const referrerPath = location.state?.referrer;
    if (["/", "/products", "/reels"].includes(referrerPath)) {
      navigate(referrerPath);
    } else {
      navigate("/");
    }
  };

  const sortOptions = [
    {
      label: "Newest",
      value: "newest",
      icon: "fa fa-calendar-plus gradient-green color-white",
    },
    {
      label: "Oldest",
      value: "oldest",
      icon: "fa fa-calendar-minus gradient-blue color-white",
    },
    {
      label: "Most Viewed",
      value: "most_viewed",
      icon: "fa fa-eye gradient-orange color-white",
    },
    {
      label: "Least Viewed",
      value: "least_viewed",
      icon: "fa fa-eye-slash gradient-gray color-white",
    },
    {
      label: "Most Liked",
      value: "most_liked",
      icon: "fa fa-thumbs-up gradient-yellow color-white",
    },
    {
      label: "Most Disliked",
      value: "most_disliked",
      icon: "fa fa-thumbs-down gradient-red color-white",
    },
    {
      label: "Shortest",
      value: "shortest",
      icon: "fa fa-compress-arrows-alt gradient-pink color-white",
    },
    {
      label: "Longest",
      value: "longest",
      icon: "fa fa-expand-arrows-alt gradient-teal color-white",
    },
    {
      label: "A to Z",
      value: "a_to_z",
      icon: "fa fa-sort-alpha-down gradient-sunny color-white",
    },
    {
      label: "Z to A",
      value: "z_to_a",
      icon: "fa fa-sort-alpha-up gradient-magenta color-white",
    },
    {
      label: "Featured",
      value: "featured",
      icon: "fa fa-star gradient-grass color-white",
    },
    {
      label: "Latest Edits",
      value: "latest_edits",
      icon: "fa fa-edit gradient-brown color-white",
    },
    {
      label: "Mix It Up",
      value: "mix_it_up",
      icon: "fa fa-random gradient-aqua color-white",
    },
  ];

  const handleFeedSortClick = (e, sort) => {
    e.preventDefault();
    navigate(`/feed/${sort}`);
  };

  return (
    <>
      <div
        ref={headerRef}
        className="header header-fixed header-logo-center header-auto-show"
      >
        <div className="header-title">{pageTitle}</div>
        {showPageTitle ? (
          <button
            ref={mainMenuEl2}
            className="header-icon header-icon-1"
            onClick={handleMainMenuClick}
          >
            <i className="fas fa-bars" />
          </button>
        ) : (
          <button
            className="header-icon header-icon-1"
            onClick={handleBackButtonClick}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
        )}
        <button
          className="header-icon header-icon-4 show-on-theme-dark"
          onClick={handleToggleTheme}
        >
          <i className="fas fa-sun" />
        </button>
        <button
          className="header-icon header-icon-4 show-on-theme-light"
          onClick={handleToggleTheme}
        >
          <i className="fas fa-moon" />
        </button>
        <button
          ref={shareMenuEl1}
          className="header-icon header-icon-3"
          onClick={handleShareMenuClick}
        >
          <i className="fas fa-share-alt" />
        </button>
      </div>
      {showPageTitle && (
        <>
          <div ref={titleRef} className="page-title page-title-fixed">
            <h1>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/");
                }}
                className="header-title color-white"
              >
                <img src={`/logo-${theme}.png`} alt="Quik Peeks" width={106} />
              </a>
            </h1>
            <button
              ref={shareMenuEl2}
              className="page-title-icon shadow-xl bg-theme color-theme"
              onClick={handleShareMenuClick}
            >
              <i className="fa fa-share-alt" />
            </button>
            <button
              onClick={handleToggleTheme}
              className="page-title-icon shadow-xl bg-theme color-theme show-on-theme-light"
            >
              <i className="fa fa-moon" />
            </button>
            <button
              onClick={handleToggleTheme}
              className="page-title-icon shadow-xl bg-theme color-theme show-on-theme-dark"
            >
              <i className="fa fa-lightbulb color-yellow-dark" />
            </button>
            <button
              ref={mainMenuEl1}
              className="page-title-icon shadow-xl bg-theme color-theme"
              onClick={handleMainMenuClick}
            >
              <i className="fa fa-bars" />
            </button>
          </div>
          <div className="page-title-clear" />
        </>
      )}
      <div className="menu-container" ref={divEl}>
        {showPageTitle && (
          <Menu
            id="menu-main"
            menuBoxLeft
            zeroRounded
            menuActive={mainMenuActive}
            width={280}
          >
            <>
              <div
                className="card rounded-0"
                style={{
                  height: 150,
                  backgroundImage: "url('/bg-side-card.jpg')",
                }}
              >
                <div className="card-top">
                  <button
                    onClick={closeMenus}
                    className={classNames(
                      "close-menu",
                      "float-end",
                      "me-2",
                      "text-center",
                      "icon-40",
                      "notch-clear",
                      { "mt-3": !frameRef, "mt-5": frameRef }
                    )}
                  >
                    <i className="fa fa-times color-white" />
                  </button>
                </div>
                <div className="card-bottom">
                  <h1 className="color-white ps-3 mb-n1 font-28">
                    <img
                      src={`/logo-dark-mode.png`}
                      alt="Quik Peeks"
                      width={120}
                    />
                  </h1>
                  <p className="mb-2 ps-3 font-12 color-white opacity-50">
                    Discover, Swipe, Shop
                  </p>
                </div>
                <div className="card-overlay bg-gradient" />
              </div>
              <h6 className="menu-divider mt-4">Feed</h6>
              <div className="list-group list-custom-small list-menu">
                <a
                  tabIndex="0"
                  role="button"
                  href="/"
                  id="nav-home"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/");
                  }}
                  className={classNames({
                    "active-nav": location.pathname === "/",
                  })}
                >
                  <i className="fa fa-home gradient-dark color-white" />
                  <span>Home</span>
                  <i className="fa fa-angle-right" />
                </a>
                {sortOptions.map((option, index) => {
                  return (
                    <a
                      tabIndex="0"
                      role="button"
                      href={`/feed/${option.value}`}
                      key={index}
                      id={`nav-${option.value}`}
                      onClick={(e) => handleFeedSortClick(e, option.value)}
                      className={classNames({
                        "active-nav": sort === option.value,
                      })}
                    >
                      <i className={option.icon} />
                      <span>{option.label}</span>
                      <i className="fa fa-angle-right" />
                    </a>
                  );
                })}
              </div>
              <h6 className="menu-divider mt-4">Site Info</h6>
              <SiteLinks />
            </>
          </Menu>
        )}
        <Menu
          id="menu-share"
          menuBoxBottom
          mediumRounded
          menuActive={shareMenuActive}
          height={370}
        >
          {!isNativeShareAvailable && <ShareMenu handleOnClose={closeMenus} />}
        </Menu>
      </div>
    </>
  );
}

export default Header;
