import { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Masonry from "react-layout-masonry";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";
import classnames from "classnames";
import { Helmet } from "react-helmet-async";
import startCase from "lodash/startCase";
import axios from "../utils/axiosConfig";
import useCategoriesStore from "../stores/categoriesStore";
import useReelsStore from "../stores/reelsStore";
import SimpleReelCard from "../components/cards/SimpleReelCard";
import { SimpleLoader } from "../components/utils/SimpleLoader";
import InfoCard from "../components/cards/InfoCard";
import useScrollToReel from "../hooks/useScrollToReel";

import { useHeaderContext } from "../contexts/HeaderContext";

function Home({ frameRef, sort: sortProp, slug }) {
  const { setMainMenuActive, setPageTitle } = useHeaderContext();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sortParam = searchParams.get("sort");
  const sort =
    (sortProp || sortParam) === null ? undefined : sortProp || sortParam;

  const { setReelsForPath, getReelsForPath } = useReelsStore();

  const currentPath = location.pathname + (location.search || "");

  const {
    reels: storedReels,
    nextPageToken: storedNextPageToken,
  } = getReelsForPath(currentPath);

  const [cardDimensions, setCardDimensions] = useState({ width: 0, height: 0 });
  const [touchedCardId, setTouchedCardId] = useState(null);
  const [isTouchMoved, setIsTouchMoved] = useState(false);
  const [loadingReels, setLoadingReels] = useState(false);
  const [currentCategory] = useState(slug);
  const [currentSort, setCurrentSort] = useState(null);

  const isEndOfContent = storedNextPageToken === null && storedReels.length > 0;

  const categories = useCategoriesStore((state) => state.categories);
  const fetchCategoriesFromStore = useCategoriesStore(
    (state) => state.fetchCategories
  );
  const loadingCategories = useCategoriesStore((state) => state.loading);

  const fetchReels = useCallback(
    async (category = null, sortParam = null, nextPageTokenParam = null) => {
      if (storedReels.length && !nextPageTokenParam) {
        return;
      }

      try {
        setLoadingReels(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/feed`,
          {
            params: {
              next_page_token: nextPageTokenParam || storedNextPageToken,
              category,
              sort: sortParam,
            },
          }
        );

        const {
          data: fetchedReels,
          next_page_token: newNextPageToken,
        } = response.data;
        setReelsForPath(currentPath, fetchedReels, newNextPageToken);
      } catch (error) {
        console.error("Error fetching peeks:", error);
      } finally {
        setLoadingReels(false);
      }
    },
    [
      storedReels,
      storedNextPageToken,
      currentPath,
      setLoadingReels,
      setReelsForPath,
    ]
  );

  const setDynamicDimensions = useCallback(() => {
    const gutter = 8;
    const numberOfColumns = 2;

    const containerWidth =
      frameRef && frameRef.current
        ? frameRef.current.offsetWidth
        : window.innerWidth;

    const cardWidth =
      (containerWidth - 30 - (numberOfColumns - 1) * gutter) / numberOfColumns;
    const cardHeight = (16 / 9) * cardWidth;

    setCardDimensions({ width: cardWidth, height: cardHeight });
  }, [frameRef]);

  const scrollId = location.state?.scrollId;
  useScrollToReel(frameRef, scrollId);

  useEffect(() => {
    let title = "Home";
    if (slug && sort) {
      title = "Categories";
    } else if (slug) {
      title = "Categories";
    } else if (sort) {
      title = "Feed";
    }

    setPageTitle(title);
  }, [slug, sort, setPageTitle]);

  useEffect(() => {
    if (categories.length <= 1 && !loadingCategories) {
      fetchCategoriesFromStore();
    }
    if (currentSort !== sort) {
      fetchReels(currentCategory, sort);
      setCurrentSort(sort);
    }
    setMainMenuActive(false);
  }, [sort, currentSort, currentCategory]); // eslint-disable-line react-hooks/exhaustive-deps

  const observer = useRef();
  const lastReelElementRef = useCallback(
    (node) => {
      if (loadingReels) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && storedNextPageToken) {
          fetchReels(currentCategory, sort, storedNextPageToken);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingReels, storedNextPageToken, currentCategory, sort, fetchReels]
  );

  useEffect(() => {
    setDynamicDimensions();
    window.addEventListener("resize", setDynamicDimensions);
    return () => {
      window.removeEventListener("resize", setDynamicDimensions);
    };
  }, [setDynamicDimensions]);

  useEffect(() => {
    const handleTouchMove = () => {
      setIsTouchMoved(true);
    };

    window.addEventListener("touchmove", handleTouchMove);

    return () => {
      window.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  useEffect(() => {
    if (isTouchMoved) {
      setIsTouchMoved(false);
    }
  }, [isTouchMoved]);

  const renderedReels = storedReels.map((reel) => (
    <SimpleReelCard
      key={reel.id}
      reel={reel}
      height={cardDimensions.height}
      width={cardDimensions.width}
      onClick={(e) => {
        e.preventDefault();
        navigate(`/peeks/${reel.short_id}`, {
          state: { referrer: "/", reel },
        });
      }}
      isTouchMoved={isTouchMoved}
      touchedCardId={touchedCardId}
      setTouchedCardId={setTouchedCardId}
    />
  ));

  if (loadingReels) {
    for (let i = 0; i < 4; i++) {
      renderedReels.push(
        <div
          className="card mx-0 mb-2 card-style"
          key={`loader-${i}`}
          style={{
            height: cardDimensions.height,
            width: cardDimensions.width,
          }}
        >
          <SimpleLoader
            height={cardDimensions.height}
            width={cardDimensions.width}
          />
        </div>
      );
    }
  }

  const findCategoryIndex = (slug) => {
    if (!slug) return 0;
    return categories.findIndex((category) => category.slug === slug);
  };

  const findCategory = (slug) => {
    if (!slug) return null;
    return categories.find((category) => category.slug === slug);
  };

  const getTitle = () => {
    const categoryName = findCategory(currentCategory)?.label;
    if (sort && slug)
      return `Feed - ${startCase(sort)} | ${categoryName} | Quik Peeks`;
    if (sort) return `Feed - ${startCase(sort)} | Quik Peeks`;
    if (slug) return `Category - ${categoryName} | Quik Peeks`;
    return "Quik Peeks";
  };

  const getDescription = () => {
    const categoryName = findCategory(currentCategory)?.label;
    const baseDescription =
      "Explore user-generated peeks featuring interesting products. Watch and discover unique and trending items in short vertical video format.";

    if (sort && slug) {
      return `Discover the latest peeks in the ${categoryName} category, sorted by ${startCase(
        sort
      )}. ${baseDescription}`;
    }
    if (sort) {
      return `Explore peeks sorted by ${startCase(sort)}. ${baseDescription}`;
    }
    if (slug) {
      return `Discover interesting products in the ${categoryName} category through engaging peeks. ${baseDescription}`;
    }
    return baseDescription;
  };

  const handleCategoryClick = (e, c) => {
    e.preventDefault();
    const newCategory = c.slug;
    if (newCategory) {
      navigate(`/categories/${newCategory}${sort ? `?sort=${sort}` : ""}`);
    } else {
      navigate(sort ? `/feed/${sort}` : "/");
    }
  };

  let renderCategorySlides;
  if (loadingCategories) {
    renderCategorySlides = [];
    for (let i = 0; i < 2; i++) {
      renderCategorySlides.push(
        <SwiperSlide
          key={`category-loader-${i}`}
          style={{
            height: 16,
            width: cardDimensions.width,
          }}
        >
          <div
            className="card mx-0 card-style"
            style={{
              height: 16,
              width: cardDimensions.width,
              marginBottom: 24,
            }}
          >
            <SimpleLoader height={16} width={cardDimensions.width} />
          </div>
        </SwiperSlide>
      );
    }
  } else {
    renderCategorySlides = categories.map((category, index) => (
      <SwiperSlide key={index}>
        <h1 className="font-16 d-block">
          <a
            href={
              category.slug
                ? `/categories/${category.slug}${sort ? `?sort=${sort}` : ""}`
                : sort
                ? `/feed/${sort}`
                : "/"
            }
            tabIndex="0"
            role="button"
            className={classnames(
              "color-theme",
              "badge",
              "font-16",
              "font-700",
              {
                "opacity-50": category.slug !== currentCategory,
              }
            )}
            onClick={(e) => handleCategoryClick(e, category)}
          >
            {category.label}
            {category.count != null ? `(${category.count})` : ""}
          </a>
        </h1>
      </SwiperSlide>
    ));
  }

  return (
    <>
      <Helmet>
        <title>{getTitle()}</title>
        <meta name="description" content={getDescription()} />
      </Helmet>
      <div className="page-content">
        <div className="category-slider">
          <Swiper
            className="home-categories-swipe"
            slidesPerView="auto"
            spaceBetween={loadingCategories ? 8 : 15}
            freeMode={true}
            modules={[FreeMode]}
            slideToClickedSlide={true}
            initialSlide={findCategoryIndex(currentCategory)}
          >
            {renderCategorySlides}
          </Swiper>
        </div>
        <div className="content mb-3">
          <Masonry columns={2} gap={8}>
            {renderedReels}
            <div ref={lastReelElementRef} />
          </Masonry>
          {isEndOfContent || renderedReels.length === 0 ? (
            <InfoCard
              heading={
                renderedReels.length === 0
                  ? "No peeks to display"
                  : "That's all for now, folks!"
              }
              subHeading={
                renderedReels.length === 0
                  ? "No peeks available in this category."
                  : "Looks like we've reached the end of the carrot patch!"
              }
              centered
            />
          ) : null}
        </div>
      </div>
    </>
  );
}

export default Home;
