import { useContext } from "react";
import ThemeContext from "../../contexts/ThemeContext";

const useThemeStyle = () => {
  const { theme } = useContext(ThemeContext);

  const getCurrentThemeColors = () => {
    const themeColors = {
      light: {
        text: "#4a4a4a",
        structure: "#f5f5f5",
        background: "#FFFFFF",
        mainFontColor: "#333333",
      },
      dark: {
        text: "#b5b5b5",
        structure: "#21252a",
        background: "#0f1117",
        mainFontColor: "#e0e0e0",
      },
    };
    return theme === "dark-mode" ? themeColors.dark : themeColors.light;
  };

  const colourStyles = {
    control: (styles, { isFocused }) => {
      const themeColors = getCurrentThemeColors();
      return {
        ...styles,
        backgroundColor: themeColors.background,
        borderColor: isFocused
          ? themeColors.mainFontColor
          : themeColors.structure,
        color: themeColors.text,
        boxShadow: isFocused ? "0 0 0 1px " + themeColors.mainFontColor : null,
        "&:hover": {
          borderColor: themeColors.mainFontColor,
        },
        borderRadius: 12,
        minHeight: 47,
      };
    },
    menu: (styles) => {
      const themeColors = getCurrentThemeColors();
      return {
        ...styles,
        backgroundColor: themeColors.background,
        borderColor: themeColors.structure,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: 12,
      };
    },
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      const themeColors = getCurrentThemeColors();
      return {
        ...styles,
        backgroundColor: isSelected
          ? themeColors.structure
          : isFocused
          ? themeColors.background
          : undefined,
        color: themeColors.mainFontColor,
        cursor: isDisabled ? "not-allowed" : "default",
        "&:hover": {
          backgroundColor: themeColors.structure,
          color: themeColors.text,
        },
      };
    },
    input: (styles) => {
      const themeColors = getCurrentThemeColors();
      return {
        ...styles,
        color: themeColors.text,
      };
    },
    placeholder: (styles) => {
      const themeColors = getCurrentThemeColors();
      return {
        ...styles,
        color: themeColors.text,
      };
    },
    singleValue: (styles) => {
      const themeColors = getCurrentThemeColors();
      return {
        ...styles,
        color: themeColors.text,
      };
    },
    dropdownIndicator: (styles) => {
      const themeColors = getCurrentThemeColors();
      return {
        ...styles,
        color: themeColors.text,
        "&:hover": {
          color: themeColors.mainFontColor,
        },
      };
    },
    clearIndicator: (styles) => {
      const themeColors = getCurrentThemeColors();
      return {
        ...styles,
        color: themeColors.text,
        "&:hover": {
          color: themeColors.mainFontColor,
        },
      };
    },
    multiValue: (styles) => {
      const themeColors = getCurrentThemeColors();
      return {
        ...styles,
        backgroundColor: themeColors.structure,
      };
    },
    multiValueLabel: (styles) => {
      const themeColors = getCurrentThemeColors();
      return {
        ...styles,
        color: themeColors.text,
      };
    },
    multiValueRemove: (styles) => {
      const themeColors = getCurrentThemeColors();
      return {
        ...styles,
        color: themeColors.text,
        "&:hover": {
          backgroundColor: themeColors.text,
          color: "white",
        },
      };
    },
  };

  return colourStyles;
};

export default useThemeStyle;
