import ContentLoader from "react-content-loader";
import PropTypes from "prop-types";

function SimpleLoader({
  width,
  height,
  speed,
  background,
  foreground,
  ...props
}) {
  return (
    <ContentLoader
      speed={speed}
      height={height}
      width={width}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor={background}
      foregroundColor={foreground}
      {...props}
    >
      <rect x="0" y="0" rx="0" ry="0" width={width} height={height} />
    </ContentLoader>
  );
}

SimpleLoader.defaultProps = {
  width: 250,
  height: 250,
  speed: 2,
  background: "#2C2C2C",
  foreground: "#636363",
};

SimpleLoader.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  speed: PropTypes.number,
  background: PropTypes.string,
  foreground: PropTypes.string,
  classnames: PropTypes.string,
};

export { SimpleLoader };
