function base64UrlEncode(str) {
  let base64 = btoa(str);
  return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

function base64UrlDecode(str) {
  str = str.replace(/-/g, "+").replace(/_/g, "/");
  while (str.length % 4) {
    str += "=";
  }
  return atob(str);
}

const setHistory = (key, value, options = {}) => {
  const { keepQuery = false, useReplaceState = false } = options;

  const location = window.location;
  const currentPath = location.pathname;
  const newValue = value
    ? currentPath.includes(key)
      ? value
      : `${key ? `${key}/` : ""}${value}`
    : key;

  const finalValue = keepQuery ? `${newValue}${location.search}` : newValue;

  if (!window.history.state || window.history.state.value !== finalValue) {
    if (useReplaceState) {
      window.history.replaceState({ value: finalValue }, null, finalValue);
    } else {
      window.history.pushState({ value: finalValue }, null, finalValue);
    }
  }
};

function relativeTimeWithoutAgo(dateString) {
  const now = new Date();
  const date = new Date(dateString);

  const secondsDiff = Math.floor((now - date) / 1000);
  const minutesDiff = Math.floor(secondsDiff / 60);
  const hoursDiff = Math.floor(minutesDiff / 60);
  const daysDiff = Math.floor(hoursDiff / 24);

  const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

  if (secondsDiff < 60) {
    return rtf.format(-secondsDiff, "second").replace(" ago", "");
  } else if (minutesDiff < 60) {
    return rtf.format(-minutesDiff, "minute").replace(" ago", "");
  } else if (hoursDiff < 24) {
    return rtf.format(-hoursDiff, "hour").replace(" ago", "");
  } else {
    return rtf.format(-daysDiff, "day").replace(" ago", "");
  }
}

export { base64UrlEncode, base64UrlDecode, setHistory, relativeTimeWithoutAgo };
