import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function Menu({
  id,
  children,
  width,
  height,
  menuBoxTop,
  menuBoxBottom,
  menuBoxLeft,
  menuBoxRight,
  zeroRounded,
  mediumRounded,
  menuActive,
  className,
}) {
  const classes = classNames(className, "menu", {
    "menu-box-top": menuBoxTop,
    "menu-box-bottom": menuBoxBottom,
    "menu-box-left": menuBoxLeft,
    "menu-box-right": menuBoxRight,
    "rounded-0": zeroRounded,
    "rounded-m": mediumRounded,
    "menu-active": menuActive,
  });

  const styles = {
    ...(width !== null ? { width } : {}),
    ...(height !== null ? { height } : {}),
  };

  return (
    <div id={id} className={classes} style={styles}>
      {children}
    </div>
  );
}

function validateSinglePropTrue(props, propName, componentName) {
  if (typeof props[propName] !== "boolean") {
    return new Error(
      `Invalid prop '${propName}' supplied to '${componentName}'. Expected 'boolean', received '${typeof props[
        propName
      ]}'.`
    );
  }
  const propNames = [
    "menuBoxTop",
    "menuBoxBottom",
    "menuBoxLeft",
    "menuBoxRight",
  ];
  if (propNames.includes(propName)) {
    if (
      props[propName] &&
      propNames.some((name) => name !== propName && props[name])
    ) {
      return new Error(
        `Only one of the props '${propNames.join(
          ", "
        )}' can be true in '${componentName}'.`
      );
    }
  }

  const roundedPropNames = ["zeroRounded", "mediumRounded"];
  if (roundedPropNames.includes(propName)) {
    if (
      props[propName] &&
      roundedPropNames.some((name) => name !== propName && props[name])
    ) {
      return new Error(
        `Only one of the props '${roundedPropNames.join(
          ", "
        )}' can be true in '${componentName}'.`
      );
    }
  }
}

Menu.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
  width: PropTypes.number,
  menuBoxTop: validateSinglePropTrue,
  menuBoxBottom: validateSinglePropTrue,
  menuBoxLeft: validateSinglePropTrue,
  menuBoxRight: validateSinglePropTrue,
  zeroRounded: validateSinglePropTrue,
  mediumRounded: validateSinglePropTrue,
  menuActive: PropTypes.bool,
  className: PropTypes.string,
};

Menu.defaultProps = {
  children: null,
  width: null,
  height: null,
  menuBoxTop: false,
  menuBoxBottom: false,
  menuBoxLeft: false,
  menuBoxRight: false,
  zeroRounded: false,
  mediumRounded: false,
  menuActive: false,
  className: "",
};

export default Menu;
