import { useParams } from "react-router-dom";
import Home from "./Home";
import { Layout } from "../App";

function Category({ frameRef }) {
  const { slug } = useParams();
  return (
    <Layout frameRef={frameRef}>
      <Home frameRef={frameRef} slug={slug} key={slug} />
    </Layout>
  );
}

export default Category;
