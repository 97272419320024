import { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";
import MuxPlayer from "@mux/mux-player-react";
import axios from "../../utils/axiosConfig";
import useFeedbackStore from "../../stores/feedbackStore";
import ActionButton from "./ActionButton";
import CustomSeeker from "./CustomSeeker";
import MuteButton from "./MuteButton";

import { useMute } from "../../contexts/MuteContext";

function Reel({
  data,
  isActive,
  handleOnDataLoad,
  frameRef,
  onCommentMenuClick,
  onShopMenuClick,
  onShareMenuClick,
  swipeUp,
}) {
  const playerRef = useRef();
  const swiperRef = useRef(null);
  const { isMuted, setIsMuted } = useMute();
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const timeParam = searchParams.get("time");

  const { feedbacks, setFeedback } = useFeedbackStore((state) => ({
    feedbacks: state.feedbacks,
    setFeedback: state.setFeedback,
  }));
  const [like, setLike] = useState(false);
  const [dislike, setDislike] = useState(false);
  const [loadingFeedback, setLoadingFeedback] = useState(true);
  const [dynamicStyles, setDynamicStyles] = useState({});
  const [showSwipeIndicator, setShowSwipeIndicator] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.muted = isMuted;
    }
  }, [isMuted]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSwipeIndicator(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const adjustStyles = () => {
      let width;

      if (frameRef && frameRef.current) {
        width = frameRef.current.clientWidth;
      } else {
        width = window.innerWidth;
      }

      const widthRatio = width / 353;

      const styles = {
        backBtnFontSize: Math.round(30 * widthRatio),
        backBtnPadding: Math.round(8 * widthRatio),
        videoTitleFontSize: Math.round(10 * widthRatio),
        videoTitlewidth: Math.round(275 * widthRatio),
        videoTitlePadding: Math.round(5 * widthRatio),
        videoTitlePaddingSides: Math.round(10 * widthRatio),
        videoTitleLineHeight: Math.round(18 * widthRatio),
        shoppableTitleLineHeight: Math.round(16 * widthRatio),
        actionButtonPadding: Math.round(10 * widthRatio),
        actionButtonIconSize: Math.round(12 * widthRatio),
        actionTextFontSize: Math.round(10 * widthRatio),
      };

      setDynamicStyles(styles);
    };

    window.addEventListener("resize", adjustStyles);
    adjustStyles();

    return () => window.removeEventListener("resize", adjustStyles);
  }, [frameRef]);

  useEffect(() => {
    const fetchFeedback = async () => {
      const cachedFeedback = feedbacks[data.id];

      if (cachedFeedback) {
        setLike(cachedFeedback.like);
        setDislike(cachedFeedback.dislike);
        setLoadingFeedback(false);
      } else {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/videos/feedback/${data.id}`
          );

          const feedbackData = response.data.data;
          if (feedbackData) {
            setLike(feedbackData.like);
            setDislike(feedbackData.dislike);
            setFeedback(data.id, feedbackData);
          }
        } catch (error) {
          console.error("Error fetching feedback:", error);
        } finally {
          setLoadingFeedback(false);
        }
      }
    };
    fetchFeedback();
    return () => {
      setLike(false);
      setDislike(false);
    };
  }, [data.id, feedbacks, setFeedback]);

  useEffect(() => {
    if (isActive) {
      handlePlay();
    } else {
      handlePause();
    }
  }, [isActive]);

  useEffect(() => {
    const timeInSeconds = Number(timeParam);
    if (timeParam !== null && !isNaN(timeInSeconds)) {
      if (timeInSeconds >= 0 && timeInSeconds <= data.duration) {
        playerRef.current.currentTime = timeInSeconds;
        handlePlay();
      }
    }
  }, [timeParam, data.duration]);

  const getSortedProducts = () => {
    if (!data.timeline || Object.keys(data.timeline).length === 0) {
      return data.products;
    }

    const productsWithTimeline = data.products.map((product) => ({
      ...product,
      timelineValue: parseFloat(data.timeline[product.id]) || 0,
    }));

    productsWithTimeline.sort((a, b) => a.timelineValue - b.timelineValue);

    return productsWithTimeline;
  };

  const sortedProducts = getSortedProducts();

  useEffect(() => {
    const getSlideIndexForCurrentTime = () => {
      if (!data.timeline || Object.keys(data.timeline).length === 0) {
        return -1;
      }
      const playbackRate = playerRef.current?.playbackRate || 1;
      const dynamicBufferBefore = 0.1 / playbackRate;
      const dynamicBufferAfter = 1.0 / playbackRate;

      let closestSlideIndex = -1;
      let minTimeDifference = Number.MAX_VALUE;

      sortedProducts.forEach((product, index) => {
        const productTime = parseFloat(data.timeline[product.id]);
        if (
          currentTime >= productTime - dynamicBufferBefore &&
          currentTime <= productTime + dynamicBufferAfter
        ) {
          const timeDifference = Math.abs(currentTime - productTime);
          if (timeDifference < minTimeDifference) {
            minTimeDifference = timeDifference;
            closestSlideIndex = index;
          }
        }
      });

      return closestSlideIndex;
    };
    const slideIndex = getSlideIndexForCurrentTime();
    if (slideIndex !== -1) {
      swiperRef.current.swiper.slideTo(slideIndex);
    }
  }, [currentTime, data.timeline, sortedProducts]);

  const handlePlay = async () => {
    try {
      if (playerRef.current && playerRef.current.readyState > 2) {
        await playerRef.current.play();
      }
    } catch (error) {
      console.error("Error playing the video:", error);
    }
  };

  const handlePause = () => {
    if (playerRef.current && playerRef.current.readyState > 2) {
      playerRef.current.pause();
    }
  };

  const handleBackButtonClick = () => {
    setIsMuted(true);
    const referrerPath = location.state?.referrer;
    if (referrerPath) {
      navigate(referrerPath);
    } else {
      navigate("/");
    }
  };

  const handleMenuClick = (menuType) => {
    handlePause();
    switch (menuType) {
      case "comment":
        onCommentMenuClick();
        break;
      case "share":
        onShareMenuClick();
        break;
      default:
        console.error("Invalid menuType provided:", menuType);
        break;
    }
  };

  const handleBuyNowClick = (product) => {
    handlePause();
    onShopMenuClick(product);
  };

  const handleFeedback = async (feedback) => {
    let newLikeState = like;
    let newDislikeState = dislike;

    if (feedback === "like") {
      newLikeState = !like;
      newDislikeState = false;
    } else if (feedback === "dislike") {
      newDislikeState = !dislike;
      newLikeState = false;
    }

    setLike(newLikeState);
    setDislike(newDislikeState);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/videos/create_feedback/${data.id}`,
        {
          like: newLikeState,
          dislike: newDislikeState,
        }
      );

      const feedbackData = response.data.data;
      if (feedbackData) {
        setFeedback(data.id, feedbackData);
      }
    } catch (error) {
      console.error("Error sending feedback:", error);
      setLike(like);
      setDislike(dislike);
    }
  };

  const shoppableSlides = sortedProducts.map((product, index) => (
    <SwiperSlide
      key={index}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
      }}
    >
      <div className="product_highlight">
        <div className="product_display">
          <div className="product_info">
            <div style={{ width: 50, height: 50, borderRadius: 8 }}>
              <img
                src={product.featured_image}
                alt={product.title}
                className="product_image"
              />
            </div>
            <div className="title_container">
              <p
                className="title_text"
                style={{
                  lineHeight: `${dynamicStyles.shoppableTitleLineHeight}px`,
                  color: "white",
                  fontSize: dynamicStyles.videoTitleFontSize,
                }}
              >
                <span>{product.title}</span>
              </p>
            </div>
          </div>
          <div style={{ height: 14, width: 1, backgroundColor: "white" }} />
          <div className="product_action">
            <div
              className="buy_btn bg-blue-dark"
              onClick={() => handleBuyNowClick(product)}
            >
              <p
                className="buy_text"
                style={{
                  color: "white",
                  fontSize: dynamicStyles.videoTitleFontSize,
                }}
              >
                View Product
              </p>
            </div>
          </div>
        </div>
      </div>
    </SwiperSlide>
  ));

  const handleOnLoadedData = () => {
    handleOnDataLoad();
  };

  return (
    <>
      <div id="mux-container" className="mux-container">
        <MuxPlayer
          streamType="on-demand"
          playbackId={data?.mux_playback_id}
          onLoadedData={handleOnLoadedData}
          onTimeUpdate={(e) => setCurrentTime(e.target.currentTime)}
          autoPlay={isActive}
          muted
          loop
          playsInline
          ref={playerRef}
          metadata={{
            video_id: data?.id,
            video_title: data?.title,
            viewer_user_id: window?.visitorId,
          }}
          preload="auto"
        />
      </div>

      <CustomSeeker playerRef={playerRef} />

      <MuteButton
        playerRef={playerRef}
        style={{
          padding: dynamicStyles.backBtnPadding,
          fontSize: dynamicStyles.backBtnFontSize,
        }}
      />

      {swipeUp && showSwipeIndicator && (
        <div className="swipe-indicator">
          <i className="fa-solid fa-arrow-up fa-bounce"></i>
          <div className="swipe-indicator-text">Swipe Up For Next</div>
        </div>
      )}
      <div className="action-buttons-container">
        <ActionButton
          type="like"
          disabled={loadingFeedback}
          loading={loadingFeedback}
          active={like}
          onClick={() => handleFeedback("like")}
          iconClassName="fas fa-thumbs-up"
          text="like"
          btnStyle={{
            padding: dynamicStyles.actionButtonPadding,
            fontSize: dynamicStyles.actionButtonIconSize,
          }}
          actionTxtstyle={{ fontSize: dynamicStyles.actionTextFontSize }}
        />
        <ActionButton
          type="dislike"
          disabled={loadingFeedback}
          loading={loadingFeedback}
          active={dislike}
          onClick={() => handleFeedback("dislike")}
          iconClassName="fas fa-thumbs-down fa-flip-horizontal"
          text="dislike"
          btnStyle={{
            padding: dynamicStyles.actionButtonPadding,
            fontSize: dynamicStyles.actionButtonIconSize,
          }}
          actionTxtstyle={{ fontSize: dynamicStyles.actionTextFontSize }}
        />
        <ActionButton
          type="comment"
          onClick={() => handleMenuClick("comment")}
          iconClassName="fas fa-comment fa-flip-horizontal"
          text="comment"
          btnStyle={{
            padding: dynamicStyles.actionButtonPadding,
            fontSize: dynamicStyles.actionButtonIconSize,
          }}
          actionTxtstyle={{ fontSize: dynamicStyles.actionTextFontSize }}
        />
        <ActionButton
          type="share"
          onClick={() => handleMenuClick("share")}
          iconClassName="fas fa-share-alt"
          text="share"
          btnStyle={{
            padding: dynamicStyles.actionButtonPadding,
            fontSize: dynamicStyles.actionButtonIconSize,
          }}
          actionTxtstyle={{ fontSize: dynamicStyles.actionTextFontSize }}
        />
      </div>
      <div
        className="video-title"
        style={{
          fontSize: dynamicStyles.videoTitleFontSize,
          width: dynamicStyles.videoTitlewidth,
          padding: dynamicStyles.videoTitlePadding,
          paddingLeft: dynamicStyles.videoTitlePaddingSides,
          paddingRight: dynamicStyles.videoTitlePaddingSides,
          lineHeight: `${dynamicStyles.videoTitleLineHeight}px`,
        }}
      >
        {data?.title}
      </div>
      <button
        className="back-button"
        onClick={handleBackButtonClick}
        style={{
          padding: dynamicStyles.backBtnPadding,
        }}
      >
        <i
          className="fas fa-arrow-left"
          style={{ color: "white", fontSize: dynamicStyles.backBtnFontSize }}
        />
      </button>
      <div className="shoppable-products">
        <Swiper
          ref={swiperRef}
          className="shoppable-products-swipe"
          direction="horizontal"
          slidesPerView={1.15}
          spaceBetween={5}
          centeredSlides={true}
          freeMode={true}
          modules={[FreeMode]}
          initialSlide={0}
          slideToClickedSlide={true}
          style={{
            position: "absolute",
            bottom: 50,
            width: "100%",
            height: 130,
          }}
        >
          {shoppableSlides}
        </Swiper>
      </div>
    </>
  );
}

export default Reel;
