import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import classNames from "classnames";
import useReelsStore from "../../stores/reelsStore";

function Footer() {
  const location = useLocation();
  const navigate = useNavigate();
  const { reels } = useReelsStore((state) => ({
    reels: state.getReelsForPath("/").reels,
  }));
  const [reelPath, setReelpath] = useState("/peeks");

  const handleReelsClick = (e) => {
    e.preventDefault();
    let path = "/peeks";
    if (reels.length) {
      const reel = reels[Math.floor(Math.random() * reels.length)];
      path = `/peeks/${reel.short_id}`;
      setReelpath(path);
      navigate(path, {
        state: { referrer: "/", reel },
      });
    } else {
      setReelpath(path);
      navigate(path);
    }
  };

  return (
    <div id="footer-bar" className="footer-bar-6">
      <Link
        to="/"
        className={classNames({ "active-nav": location.pathname === "/" })}
      >
        <i className="fa-solid fa-book-atlas" />
        <span>Browse</span>
        <em></em>
      </Link>
      <Link
        to={reelPath}
        rel={reelPath === "/peeks" ? "noindex nofollow" : undefined}
        onClick={handleReelsClick}
        className={classNames(
          { "active-nav": location.pathname === "/peeks" },
          "circle-nav"
        )}
      >
        <i className="fa fa-film" />
        <span>Watch</span>
        <em></em>
        <strong>
          <u></u>
        </strong>
      </Link>
      <Link
        to="/search?q="
        className={classNames({
          "active-nav": location.pathname === "/search",
        })}
      >
        <i className="fa-solid fa-magnifying-glass" />
        <span>Search</span>
        <em></em>
      </Link>
    </div>
  );
}

export default Footer;
